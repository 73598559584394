// Lib
import { ThemeFile } from 'lib/context/ThemeContext';

export const ReviewSummaryTheme: ThemeFile = {
  aw: {
    classes: {},
  },
  rba: {
    classes: {
      summaryTitle: 'font-serif font-bold border-t border-t-gray pt-s w-[92%] sm:ml-m text-s',
      ratingsWrapper: 'md:w-auto md:items-center hidden md:!block md:px-l',
      ratingsAndReviewCountWrapper:
        'flex flex-row md:py-xs items-center lg:w-auto lg:justify-center gap-2 md:mb-m md:w-1/4 md:pr-m',
      ratingText: 'md:ml-1 font-medium font-sans text-m md:text-xl',
      ratingsIconsList: 'flex hover:bg-light-gray w-fit gap-2 md:pr-4 rating-button',
      progressBarWrapper: 'px-xxs mt-xxs  md:w-[30vw] md:mt-0 reviewBar',
      totalReviewsText:
        'flex text-xxs font-regular font-serif items-end pr-s text-dark-gray justify-center gap-xxs md:pr-0 md:items-center',
    },
  },
};
