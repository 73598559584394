import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import {
  Placeholder,
  //VisitorIdentification,
  LayoutServiceData,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { ThemeName, useTheme } from 'lib/context/ThemeContext';
import MetaData from './helpers/Meta/MetaData';
import { useRecentlyViewed } from './lib/utils/useRecentlyViewed';
import { useFavorites } from './lib/utils/useFavorites';
import FixLinks from './helpers/FixLinks/FixLinks';
import HeadScripts from './helpers/HeadScripts/HeadScripts';
import { useRouter } from 'next/router';
import ReactHtmlParser from 'react-html-parser';
import { EwSiteInfo } from 'lib/site/ew-site-info';
import { Feature } from './.generated/Feature.EnterpriseWeb.model';
import { useAffiliate } from 'lib/context/AffiliateContext';
import { CURRENTZIP_SYMBOL, CURRENTSTATE_SYMBOL } from 'lib/affiliate/affiliate-middleware';
import { getCookie } from 'cookies-next';

// Prefix public assets with a public URL to enable compatibility with Sitecore editors.
// If you're not supporting Sitecore editors, you can remove this.
interface LayoutProps {
  layoutData: LayoutServiceData;
  requestedPath?: string;
  site: EwSiteInfo;
}

const FontLinks: Record<ThemeName, string> = {
  aw: 'https://use.typekit.net/pev3rfw.css',
  rba: 'https://use.typekit.net/shy7gxo.css',
};

const Layout = ({ layoutData, requestedPath, site }: LayoutProps): JSX.Element => {
  const { route } = layoutData.sitecore;

  const { themeName } = useTheme();

  const { favoriteProducts, favoriteProductsCount } = useFavorites();

  const recentlyViewedLinks = useRecentlyViewed();

  const affiliate = useAffiliate();

  const isPageEditing = layoutData.sitecore.context.pageEditing;

  const mainClassPageEditing = isPageEditing ? 'editing-mode' : 'prod-mode';

  const beforeHeadCloseScript = route?.fields?.pageAddScriptsBeforeHeadClose?.value as string;

  const afterBodyOpenScript = route?.fields?.pageAddScriptsAfterBodyOpen?.value as string;

  const beforeBodyCloseScript = route?.fields?.pageAddScriptsBeforeBodyClose?.value as string;
  const articleSchema = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    author: {
      '@type': 'Organization',
      name: 'Andersen Windows',
      url: `${process.env.PUBLIC_URL}${layoutData.sitecore?.context?.itemPath}`,
    },
    dateModified: layoutData.sitecore?.route?.fields?.lastUpdated?.value,
    datePublished: layoutData.sitecore?.route?.fields?.articleDate?.value,
    headline: layoutData.sitecore?.route?.fields?.articleTitle?.value,
    //@ts-ignore we are using custom fields
    image: [layoutData.sitecore.route?.fields?.primaryImage?.value?.src],
  };
  useEffect(() => {
    const beforeHeadCloseFragment = document
      .createRange()
      .createContextualFragment(beforeHeadCloseScript);
    document.head.append(beforeHeadCloseFragment);

    const afterBodyOpenFragment = document
      .createRange()
      .createContextualFragment(afterBodyOpenScript);
    document.body.prepend(afterBodyOpenFragment);

    const beforeBodyCloseFragment = document
      .createRange()
      .createContextualFragment(beforeBodyCloseScript);
    document.body.append(beforeBodyCloseFragment);

    return () => {
      // Handles the case where element is already removed, in that case there would be no parent
      // element and it will do nothing rather than throw an error.
      beforeHeadCloseFragment.parentElement?.removeChild(beforeHeadCloseFragment);
      afterBodyOpenFragment.parentElement?.removeChild(afterBodyOpenFragment);
      beforeBodyCloseFragment.parentElement?.removeChild(beforeBodyCloseFragment);
    };
  }, [afterBodyOpenScript, beforeBodyCloseScript, beforeHeadCloseScript, themeName]);

  const [fontStyleLoaded, setFontStyleLoaded] = useState(false);
  const router = useRouter();
  const urlPath = router.asPath;

  // On document load, change the font stylesheet back to 'all'.
  // This will make the font load non-blocking to improve load time.
  // Technique from https://www.filamentgroup.com/lab/load-css-simpler/
  // but adopted to work with React because React was re-rendering the link
  // and thus swapping the `media` style each re-rendering causing FOUT.
  useEffect(() => {
    // get link element using querySelecor
    const fontStyleElement = document.querySelector('.fontStyleLink');

    // Update the state once the link is loaded, to update media attribute to 'all'
    if (fontStyleElement) {
      setFontStyleLoaded(true);
    }
  }, []);
  /* Bold Orange datalayer use  for MCP */
  const extractAndCombineTitleValues = (data: Field | undefined): string | undefined => {
    if (!data) {
      return undefined;
    }

    const field = data as unknown as Feature.EnterpriseWeb.Enterprise.Data.Search.FacetTag[];

    if (field?.length > 0) {
      return field.map((item) => item?.fields?.title?.value).join(',');
    }

    return undefined;
  };

  // GTM
  // See https://andersenwindows.atlassian.net/wiki/spaces/EW/pages/3275096077/Local+Google+Tag+Manager+Development for Developer information

  useEffect(() => {
    if (!site.gtmId) {
      return;
    }

    const gtmInitData: TagManagerArgs = {
      gtmId: site.gtmId as string,
      auth: site.gtmAuth as string,
      preview: site.gtmEnvironment as string,
    };

    /* Bold Orange datalayer use  for MCP */
    if (site.name === 'AndersenWindows' && themeName === 'aw') {
      const getCategoryName = () => {
        const fields = layoutData?.sitecore?.route?.fields;
        if (fields && Array.isArray(fields?.windowType) && fields?.windowType?.length > 0) {
          return fields?.windowType;
        } else if (fields && Array.isArray(fields?.doorType)) {
          return fields?.doorType;
        }
        return undefined;
      };
      //Page Levels
      const buildPageLevels = (pageLevels?: string[]) => {
        if (!pageLevels?.length) {
          return {};
        }
        return pageLevels.reduce((levels, level, index) => {
          if (level) {
            levels[`page_level_${index + 1}`] = level.toLowerCase();
          }
          return levels;
        }, {} as Record<string, string>);
      };
      const pageLevels = (layoutData.sitecore.context?.itemPath as string)
        ?.replace(/^\/+|\/+$/g, '')
        ?.split('/');

      gtmInitData['dataLayer'] = {
        pageType: layoutData.sitecore.route?.fields?.pageType?.value,
        product: layoutData.sitecore.route?.fields?.breadcrumbTitle?.value,
        doorType: extractAndCombineTitleValues(layoutData.sitecore.route?.fields?.doorType),
        productType: extractAndCombineTitleValues(layoutData.sitecore.route?.fields?.productType),
        windowType: extractAndCombineTitleValues(layoutData.sitecore.route?.fields?.windowType),
        productSeries: extractAndCombineTitleValues(
          layoutData.sitecore.route?.fields?.productSeries
        ),
        pageInformation: {
          ...buildPageLevels(pageLevels),
          product_series: extractAndCombineTitleValues(
            layoutData.sitecore.route?.fields?.productSeries
          ),
          product_type: extractAndCombineTitleValues(
            layoutData.sitecore.route?.fields?.productType
          ),
          page_type: layoutData.sitecore.route?.fields?.pageType?.value,
          category_name: extractAndCombineTitleValues(getCategoryName()), // Handle multi-select
          product_name: layoutData.sitecore.route?.fields?.breadcrumbTitle?.value, // Breadcrumb-based product name
        },
        siteInformation: {
          brand: site.name,
        },
      };
    }

    TagManager.initialize(gtmInitData);
    if (site.hasAffiliatePersonalization) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'marketLoad',
          affiliateInformation: {
            store_number: `${affiliate.userAffiliate.affiliateId}`,
            store_location: `${affiliate.userAffiliate.city}, ${affiliate.userAffiliate.state.abbreviation}`, // City, State
            zip_code: `${getCookie(CURRENTZIP_SYMBOL)}`,
            state: `${getCookie(CURRENTSTATE_SYMBOL)}`, // Two-letter state abbreviation
          },
        },
      });
    }
    const handleDocumentClick = (event: Event) => {
      if (event.target instanceof Element) {
        const target = event.target;

        // Handle click for authored CTAs
        if (target.hasAttribute('data-gtm-click')) {
          const dataLayer: Record<string, string | null> = {};

          for (const name of target.getAttributeNames()) {
            if (name.startsWith('data-gtm-dl-')) {
              const key = name.replace('data-gtm-dl-', '').replaceAll('-', '_');
              dataLayer[key] = target.getAttribute(name);
            }
          }

          TagManager.dataLayer({ dataLayer });
          return;
        }

        // Handle click for RTE CTAs
        // Note: We don't need to handle nav_click gtm event as its not likely to have the RTE in navigation components
        else if (target instanceof HTMLAnchorElement && target.closest('div.body-copy')) {
          TagManager.dataLayer({
            dataLayer: {
              event: target.href.startsWith('tel:') ? 'click_to_call' : 'cta_click',
            },
          });
        }
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };

    // We can ignore react-hooks/exhaustive-deps warning for this useEffect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.gtmId, router?.asPath]);

  return (
    <>
      <Head>
        {/* https://css-tricks.com/how-to-load-fonts-in-a-way-that-fights-fout-and-makes-lighthouse-happy/ */}
        {/* Preconnect/reload as early as possible */}
        <link rel="preconnect" href="https://use.typekit.net" crossOrigin="" />
        <link rel="preconnect" href="https://p.typekit.net" crossOrigin="" />
        <link rel="preload" href={FontLinks[themeName]} as="style" />
        {themeName === 'aw' && <link rel="icon" href="/awfavicon.svg" />}
        {themeName === 'rba' && <link rel="icon" href="/rbafavicon.png" />}

        {urlPath === '/' &&
          site.name === 'AndersenWindows' &&
          themeName === 'aw' &&
          ReactHtmlParser(
            '<script type="application/ld+json">{"@context":"http://schema.org","@type":"Organization","@id":"https://www.andersenwindows.com/","url":"https://www.andersenwindows.com","email":"Example@example.com","name":"Andersen Windows","telephone":"+1-800-426-4261","logo":"https://techpub1.andersenwindows.com//Publications/Images/AW_Logo.png","sameAs":["https://www.facebook.com/AndersenWindows","https://www.pinterest.com/andersenwindows/","https://www.instagram.com/andersen_windows/","https://www.houzz.com/photos/andersen-windowsphbr0lbl-bl~l_8256","https://www.youtube.com/user/AndersenWindow","https://www.wikidata.org/wiki/Q4753960","https://twitter.com/andersenwindow/123"]}</script>'
          )}
        {/* Load font style as print and swap to all to make it non-blocking */}
        <link
          className="fontStyleLink"
          rel="stylesheet"
          href={FontLinks[themeName]}
          // Delay loading font until document is loaded.  See useEffect above.
          media={fontStyleLoaded ? 'all' : 'print'}
        />
        {requestedPath &&
          layoutData.sitecore?.route?.templateId === '9256edf3-d0fa-4588-adda-3036b9d04faa' && (
            <script
              type="application/ld+json"
              id="article-schema"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(articleSchema) }}
            />
          )}
        <noscript>
          <link rel="stylesheet" href={FontLinks[themeName]} />
        </noscript>
      </Head>
      <MetaData requestedPath={requestedPath} hostName={site.canonicalHostName} />
      <HeadScripts />
      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      {/*<VisitorIdentification />*/}

      {/* root placeholder for the app, which we add components to using route data */}
      <div className={`${mainClassPageEditing}`}>
        {/* Inline styling is required for any element between the mainClassPageEditing div element and the <Placeholder> so that elements within the header
            can use the main content div for position purposes. */}
        <header className="inline">
          <div id="header" className="inline">
            {route && (
              <Placeholder
                name="headless-header"
                rendering={route}
                favoriteProductsCount={favoriteProductsCount}
              />
            )}
          </div>
        </header>
        <main>
          <div id="hero">
            {route && (
              <Placeholder
                name="headless-hero"
                rendering={route}
                favoriteProducts={favoriteProducts}
              />
            )}
          </div>
          <div id="main">
            {route && (
              <Placeholder
                name="headless-main"
                rendering={route}
                recentlyViewedLinks={recentlyViewedLinks}
                favoriteProducts={favoriteProducts}
                hideStickyForm={route?.fields?.hideStickyForm?.value || false}
              />
            )}
          </div>
          {/* Added per support case CS0429235 until we upgrade to new version of JSS*/}
          <FixLinks></FixLinks>
        </main>
        <div id="pre-footer">
          {route && <Placeholder name="headless-prefooter" rendering={route} />}
        </div>
        <footer>
          <div id="footer">{route && <Placeholder name="headless-footer" rendering={route} />}</div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
