import { NextRequest, NextResponse } from 'next/server';

import { defaultAffiliateGeolocationProps } from 'lib/affiliate/geolocation';
import { Debug } from 'lib/constants/debug';
import {
  AffiliateGeolocationService,
  AffiliateGeolocationServiceConfig,
} from './geolocation/affiliate-geolocation-service';
import { MiddlewareBase, MiddlewareBaseConfig } from 'lib/jss21.2.1/middleware/middleware';
import { EwSiteInfo } from 'lib/site/ew-site-info';
import { getAffiliateRewrite } from './utils';

export type AffiliateMiddlewareConfig = Omit<MiddlewareBaseConfig, 'disabled'> &
  Omit<AffiliateGeolocationServiceConfig, 'fetch'>;

export const AFFILIATE_SYMBOL = 'ew_affiliate';
export const CURRENTZIP_SYMBOL = 'currentZip';
export const CURRENTSTATE_SYMBOL = 'currentState';
export const CURRENTCITY_SYMBOL = 'currentCity';

/**
 * Middleware / handler for affiliate support
 */
export class AffiliateMiddleware extends MiddlewareBase {
  protected AFFILIATE_SYMBOL = AFFILIATE_SYMBOL;

  private affiliateGeolocationService: AffiliateGeolocationService;
  protected CURRENTZIP_SYMBOL = CURRENTZIP_SYMBOL;

  /**
   * @param {AffiliateMiddlewareConfig} [config] Afifliate middleware config
   */
  constructor(protected config: AffiliateMiddlewareConfig) {
    super(config);
    this.affiliateGeolocationService = new AffiliateGeolocationService({ ...config, fetch: fetch });
  }

  /**
   * Gets the Next.js middleware handler with error handling
   * @returns middleware handler
   */
  public getHandler(): (req: NextRequest, res?: NextResponse) => Promise<NextResponse> {
    return async (req, res) => {
      try {
        return await this.handler(req, res);
      } catch (error) {
        console.log('Affiliate middleware failed:');
        console.log(error);
        return res || NextResponse.next();
      }
    };
  }

  hasAffiliatePersonalization(req: NextRequest, res?: NextResponse) {
    const siteInfo = this.getSite(req, res) as EwSiteInfo;
    return siteInfo.hasAffiliatePersonalization?.toLowerCase() === 'true';
  }

  private handler = async (req: NextRequest, res?: NextResponse): Promise<NextResponse> => {
    const pathname = req.nextUrl.pathname;
    const hostname = this.getHostHeader(req) || this.defaultHostname;
    const ip = req.ip || req.headers.get('x-forwarded-for')?.split(',')[0];

    Debug.affiliates('affiliate middleware start: %o', {
      pathname,
      hostname,
      ip,
    });

    // Response will be provided if other middleware is run before us
    let response = res || NextResponse.next();

    // Path can be rewritten by previously executed middleware
    const basePath = res?.headers.get('x-sc-rewrite') || pathname;

    if (
      this.isPreview(req) ||
      this.excludeRoute(pathname) ||
      !this.hasAffiliatePersonalization(req, res)
    ) {
      Debug.affiliates(
        'skipped (%s)',
        this.isPreview(req)
          ? 'preview'
          : this.excludeRoute(pathname)
          ? 'route excluded'
          : 'no affiliate personalization'
      );
      return response;
    }

    // Site name can be forced by query string parameter or cookie
    let affiliateId =
      req.nextUrl.searchParams.get(this.AFFILIATE_SYMBOL) || req.cookies.get(this.AFFILIATE_SYMBOL);

    let currentzip =
      req.nextUrl.searchParams.get(this.CURRENTZIP_SYMBOL) ||
      req.cookies.get(this.CURRENTZIP_SYMBOL);

    let currentState =
      req.nextUrl.searchParams.get(CURRENTSTATE_SYMBOL) || req.cookies.get(CURRENTSTATE_SYMBOL);

    let currentCity =
      req.nextUrl.searchParams.get(CURRENTCITY_SYMBOL) || req.cookies.get(CURRENTCITY_SYMBOL);

    if (!currentzip || !currentState || !currentCity) {
      const affiliateGeolocationResponse = await this.getAffiliateIdFromGeo(req);
      affiliateId = affiliateId || affiliateGeolocationResponse?.affiliateId;
      currentzip = affiliateGeolocationResponse?.zipcode;
      currentState = affiliateGeolocationResponse?.state;
      currentCity = affiliateGeolocationResponse?.city;
    }

    currentzip && response.cookies.set(this.CURRENTZIP_SYMBOL, currentzip);
    currentState && response.cookies.set(CURRENTSTATE_SYMBOL, currentState);
    currentCity && response.cookies.set(CURRENTCITY_SYMBOL, currentCity);

    // Rewrite to site specific path
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const rewritePath = getAffiliateRewrite(basePath, affiliateId!);

    // Note an absolute URL is required: https://nextjs.org/docs/messages/middleware-relative-urls
    const rewriteUrl = req.nextUrl.clone();

    rewriteUrl.pathname = rewritePath;

    response = NextResponse.rewrite(rewriteUrl, res);

    // Share site name with the following executed middlewares
    response.cookies.set(this.AFFILIATE_SYMBOL, affiliateId);

    // Update the rewrite path
    response.headers.set('x-sc-rewrite', rewritePath);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    response.headers.set('x-ew-affiliate', affiliateId!);

    Debug.affiliates('affiliate middleware end: %o', {
      rewritePath,
      affiliateId,
      headers: this.extractDebugHeaders(response.headers),
      cookies: response.cookies,
    });

    return response;
  };

  async getAffiliateIdFromGeo(
    req: NextRequest
  ): Promise<{ affiliateId: string; zipcode: string; state: string; city: string }> {
    try {
      const ip = req.ip || req.headers.get('x-forwarded-for')?.split(',')[0];

      const geoResponse = await this.affiliateGeolocationService.getAffiliateGeolocationFromIp(ip);

      if (geoResponse?.postalCode) {
        return {
          affiliateId:
            geoResponse?.affiliateId?.toString() ||
            defaultAffiliateGeolocationProps.affiliateId.toString(),
          zipcode: geoResponse?.postalCode,
          state: geoResponse?.stateOrProvince ?? '',
          city: geoResponse?.city ?? '',
        };
      }

      Debug.affiliates(
        `No geolocation data for ${ip}`,
        `Geo API response: ${JSON.stringify(geoResponse)}`
      );
      return {
        affiliateId: defaultAffiliateGeolocationProps.affiliateId.toString(),
        zipcode: '',
        state: '',
        city: '',
      };
    } catch (e) {
      console.error(e);
      return {
        affiliateId: defaultAffiliateGeolocationProps.affiliateId.toString(),
        zipcode: '',
        state: '',
        city: '',
      };
    }
  }
}
