import classNames from 'classnames';
import { ThemeFile } from 'lib/context/ThemeContext';
import { BackgroundColor } from './ContentBlock';
import { ButtonVariants } from 'src/helpers/Button';
import { getEnum } from 'lib/utils';

const getDynamicButtonStyles = (backgroundColor: BackgroundColor): string => {
  return classNames(`${backgroundColor === 'black' ? 'text-primary' : ''}`);
};

const getCTAPadding = (ctaStyle: any) => {
  let padding = '';
  if (getEnum<ButtonVariants>(ctaStyle) === 'link') {
    padding = 'ml-0 px-s md:px-0';
  } else if (getEnum<ButtonVariants>(ctaStyle) === 'link-right-icon') {
    padding = 'ml-0 px-s md:px-0 !pr-[50px]';
  }
  return padding;
};

export const ContentBlockTheme = (backgroundColor: BackgroundColor, fields: any): ThemeFile => {
  return {
    aw: {
      classes: {
        contentWrapper: classNames(
          backgroundColor != 'white' ? 'py-l' : '',
          'col-span-12',
          'px-m',
          'md:w-full',
          'md:max-w-screen-lg',
          'md:mx-auto'
        ),
        headlineClass: 'text-theme-text text-sm-m md:text-m font-bold mb-s',
        bodyClass: 'text-theme-body mb-s',
        buttonGroupClass: {
          wrapper: 'flex-col w-fit',
          cta1Classes: classNames(
            getDynamicButtonStyles(backgroundColor),
            getCTAPadding(fields?.cta1Style),
            'mb-m md:mb-0 w-auto'
          ),
          cta2Classes: classNames(
            getDynamicButtonStyles(backgroundColor),
            getCTAPadding(fields?.cta2Style),
            ' w-auto'
          ),
          cta3Classes: classNames(getCTAPadding(fields?.cta3Style), ' mt-6 md:mt-0 w-auto'),
        },
      },
    },
    rba: {
      classes: {
        contentWrapper: classNames(
          backgroundColor != 'white' ? 'py-l' : '',
          'col-span-12',
          'px-m',
          'md:w-full',
          'md:max-w-screen-lg',
          'md:mx-auto'
        ),
        headlineClass: 'text-theme-text text-sm-m md:text-m font-medium mb-s',
        bodyClass: 'text-theme-body mb-s',
        buttonGroupClass: {
          wrapper: 'flex-col w-fit',
          cta1Classes: classNames(
            getCTAPadding(fields?.cta1Style),
            getDynamicButtonStyles(backgroundColor),
            'mr-2 w-auto'
          ),
          cta2Classes: classNames(
            getDynamicButtonStyles(backgroundColor),
            getCTAPadding(fields?.cta2Style),
            'my-s md:my-0 w-auto'
          ),
          cta3Classes: classNames(getCTAPadding(fields?.cta3Style), ' md:mt-0 w-auto'),
        },
      },
    },
  };
};
