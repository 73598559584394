import { useSitecoreContext, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { getEnum, getHeadingLevel } from 'lib/utils';
import { useTheme } from 'lib/context/ThemeContext';
import Component from 'src/helpers/Component/Component';
import Headline from 'src/helpers/Headline/Headline';
import { HeroSimpleTheme } from './HeroSimple.theme';
import { getShowroom } from 'lib/utils/showroom-utils';

export type HeroSimpleProps = Feature.EnterpriseWeb.Enterprise.Components.Hero.HeroSimple;

export type BackgroundColor = 'black' | 'gray' | 'white';

const HeroSimple = (props: HeroSimpleProps): JSX.Element => {
  const context = useSitecoreContext();
  const backgroundColor = getEnum<BackgroundColor>(props.fields?.backgroundColor) || 'white';
  const { themeData } = useTheme(HeroSimpleTheme(backgroundColor));
  const headlineProps = props;
  const showroomData = getShowroom({ context });

  // if showroom page, use the showroom's Showroom Name as the headline if headline is empty.
  if (showroomData.showroom && !headlineProps?.fields?.headlineText?.value) {
    headlineProps.fields.headlineText =
      showroomData.showroomPage?.fields?.showroom?.fields?.showroomName;
  }

  return (
    <Component
      variant="full"
      backgroundVariant={getEnum(props.fields?.backgroundColor)}
      padding={'px-0'}
      dataComponent="general/herosimple"
      {...props}
    >
      <div className="col-span-12">
        <div className="px-m md:max-w-screen-lg lg:mx-auto">
          <Headline
            useTag={getHeadingLevel('h1', headlineProps.fields?.headlineLevel)}
            classes={themeData.classes.heroContainer}
            {...headlineProps}
          />
        </div>
      </div>
    </Component>
  );
};

export default withDatasourceCheck()(HeroSimple);
