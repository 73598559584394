// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
import { Field, Item, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import { ReviewsListTheme } from './ReviewsList.theme';
import { getReviewsDetails, parseReviewFilterData } from 'lib/reviews/get-reviews';
import { ReviewDetails, ReviewProduct, SortBy } from 'lib/reviews/types';
import { format } from 'date-fns';
import { StarRating } from 'src/helpers/StarRating';
import ReviewProductImage from '../ReviewProductImage/ReviewProductImage';
import { useAffiliate } from 'lib/context/AffiliateContext';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import { useEffect, useState } from 'react';
import SvgIcon from '../SvgIcon/SvgIcon';
import { useReviewSummaryStore } from 'components/reviews/ReviewSummary/reviewsummary.store';

export type FetchReviewProps = {
  pageNumber: number;
  pageSize: number;
};

export type ReviewsListProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.Reviews.ReviewSummaryAndList;

const ReviewsList = (props: ReviewsListProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [reviews, setReviews] = useState<ReviewDetails[]>([]);
  const { themeData } = useTheme(ReviewsListTheme);
  const { userAffiliate, pageAffiliate } = useAffiliate();
  const { currentScreenWidth } = useCurrentScreenType();
  const numberOfReviews = props.fields?.reviewListPageSize?.value ?? 10;
  const loadMoreText = props?.fields?.loadMoreText?.value || 'Load More';
  const showProductImages: boolean = props?.fields?.showProductImages?.value;
  const sortField = props?.fields?.sortByOptions;
  const [sortOptions, setSortOptions] = useState<SortBy>({
    propertyName: 'review.submissionDate',
    sortExpression: 'DESC',
  });

  const { exactRating, setExactRating } = useReviewSummaryStore();

  const fetchData = async (fetchPageNumber: number, sortBy: SortBy) => {
    return await getReviewsDetails(
      parseReviewFilterData({
        reviewFilterProps: {
          ...props,
          pageSize: numberOfReviews,
          pageNumber: fetchPageNumber,
          sortBy: sortBy,
          exactRating: exactRating,
        },
        userAffiliate: userAffiliate,
        pageAffiliate: pageAffiliate || undefined,
      })
    );
  };

  useEffect(() => {
    const getData = async (fetchPageNumber: number, sortBy: SortBy) => {
      const data = await fetchData(fetchPageNumber, sortBy);
      if (pageNumber === 1) {
        setReviews(data);
      } else {
        setReviews(reviews.concat(data));
      }
    };

    getData(pageNumber, sortOptions);
  }, [exactRating, sortOptions, pageNumber]);

  const loadMoreClick = async () => {
    setPageNumber(pageNumber + 1);
  };

  const sortChange = async (e: any) => {
    const sortField = e?.target?.selectedOptions[0]?.value;
    const sortFieldSplit = sortField.split(' ');
    if (sortFieldSplit.length === 2) {
      const newSortOption: SortBy = {
        propertyName: sortFieldSplit[0],
        sortExpression: sortFieldSplit[1],
      };
      setExactRating(0);
      setSortOptions(newSortOption);
    }
  };

  const isMobile = currentScreenWidth < getBreakpoint('md');
  const starSize = isMobile ? '16' : '24';

  return (
    <div data-component="reviews/reviewslist" className={themeData.classes.reviewListWrapper}>
      <div className="float-right">
        <span className="text-xxs font-bold">SORT BY</span>
        <select
          name="sortOptions"
          className="border-none text-xs font-normal md:pr-ml"
          onChange={sortChange}
        >
          {sortField.map((_sortOption: Item) => {
            const name = _sortOption.name;
            const fieldValue = _sortOption.fields?.Value as Field;
            return (
              <>
                <option value={fieldValue.value.toString()}>{name}</option>
              </>
            );
          })}
        </select>
      </div>
      <div className={themeData.classes.reviewList}>
        {reviews?.map((_review: ReviewDetails) => {
          const date = format(new Date(_review.review.submissionDate), 'MMMM do, YYYY');
          return (
            <>
              <div className={themeData.classes.review}>
                <div className={themeData.classes.ratingSection}>
                  <div className={themeData.classes.ratingText}>
                    <h2>{_review.review.reviewRating}</h2>
                  </div>
                  <div className={themeData.classes.ratingStars}>
                    <StarRating reviewStars={_review.review.reviewRating} iconSize={starSize} />
                  </div>
                </div>
                <div
                  className={
                    showProductImages
                      ? themeData.classes.reviewSection
                      : themeData.classes.reviewSectionNoProductImages
                  }
                >
                  <div className={themeData.classes.reviewNameSection}>
                    <span className={themeData.classes.reviewName}>{_review.customerName}</span>
                    {_review?.city && _review?.state && (
                      <span className={themeData.classes.reviewLocation}>
                        &nbsp;from {_review.city}, {_review.state}
                      </span>
                    )}
                  </div>
                  <div className={themeData.classes.reviewDateSection}>
                    <span className={themeData.classes.reviewDate}>Posted on {date}</span>
                  </div>
                  <span
                    className={
                      showProductImages
                        ? themeData.classes.reviewText
                        : themeData.classes.reviewTextNoProductImages
                    }
                  >
                    {_review.review.reviewText}
                  </span>
                </div>
                {showProductImages && (
                  <div className={themeData.classes.productSection}>
                    <div>
                      <span className={themeData.classes.productSectionHeader}>
                        Product(s) installed
                      </span>
                    </div>
                    <div className={themeData.classes.productSectionImages}>
                      {_review?.products?.map((_product: ReviewProduct, index) => {
                        const formattedProductName = _product?.productName?.replace('_', ' ') || '';
                        const originalProductNameLower = _product?.productName?.toLowerCase();
                        return index < 3 ? (
                          <>
                            <div className={themeData.classes.productSectionImageWrapper}>
                              <ReviewProductImage productName={originalProductNameLower} />
                              <div className={themeData.classes.productSectionProductName}>
                                {formattedProductName}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        })}
        <div className={themeData.classes.loadMoreContainer}>
          <button
            type="button"
            onClick={loadMoreClick}
            className={themeData.classes.loadMoreButton}
          >
            <span className={themeData.classes.loadMoreText}>{loadMoreText}</span>
            <SvgIcon icon="caret" size="lg" className="loadMoreIcon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default withDatasourceCheck()<ReviewsListProps>(ReviewsList);
