import { Affiliate } from 'lib/context/AffiliateContext';
import {
  AFFILIATE_SYMBOL,
  CURRENTSTATE_SYMBOL,
  CURRENTZIP_SYMBOL,
  CURRENTCITY_SYMBOL,
} from 'lib/affiliate/affiliate-middleware';

export const CANADIAN_ZIP_REGEX =
  '^[ABCEGHJ-NPRSTVXYabceghj-nprstvxy][0-9]ABCEGHJ-NPRSTV-Zabceghj-nprstv-z?$';
export const US_ZIP_REGEX = '^[0-9]{5}$';
export type SiteType = 'US' | 'Canada';

export const checkAndSetSearchParams = (url: URL, searchParam: string, paramValue: string) => {
  url.searchParams.has(searchParam)
    ? url.searchParams.set(searchParam, paramValue)
    : url.searchParams.append(searchParam, paramValue);
};

export const GetDomainURL = (
  data: Affiliate,
  zipInputValue: string,
  isCrossDomainZipType: boolean
) => {
  const parsedUrl = new URL(window.location.href);
  [
    { searchParam: AFFILIATE_SYMBOL, paramValue: data?.affiliateId },
    { searchParam: CURRENTZIP_SYMBOL, paramValue: zipInputValue },
    { searchParam: CURRENTSTATE_SYMBOL, paramValue: data?.state?.abbreviation },
    { searchParam: CURRENTCITY_SYMBOL, paramValue: data?.city },
  ].forEach((item) => checkAndSetSearchParams(parsedUrl, item.searchParam, item.paramValue));

  if (isCrossDomainZipType) {
    if (/(com|ca)$/.test(parsedUrl.hostname)) {
      parsedUrl.hostname = parsedUrl.hostname.replace(/\.(com|ca)/g, (_: string, domain: string) =>
        domain === 'com' ? '.ca' : '.com'
      );
    }
  }
  return parsedUrl.toString();
};
