import React, { useEffect, useRef, useState } from 'react';
import { FormFieldProps } from 'lib/forms';
import { FormikValues, useFormikContext } from 'formik';
import ModalWrapper, { ModalSize } from 'src/helpers/ModalWrapper/ModalWrapper';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { getEnum } from 'lib/utils';
import { OnlineSchedulingConstants } from 'lib/constants/online-scheduling';
import { useModalIdContext } from 'lib/context/GenericModalIDContext';
import { Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-nextjs';
import { OnlineSchedulingDispatchAction } from 'lib/forms/OnlineScheduling/OnlineSchedulingTypes';
import { useOnlineSchedulingDispatch } from 'lib/forms/OnlineScheduling/OnlineSchedulingContext';
import ReactDOM from 'react-dom';
import { useTheme } from 'lib/context/ThemeContext';

export type OSInitProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Forms.Events.OnlineSchedulingInitInModal &
    FormFieldProps &
    RouteData;

const OnlineSchedulingInitInModal = (props: OSInitProps): JSX.Element => {
  const { values } = useFormikContext<FormikValues>();
  const elementRef = useRef<HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { setIsGenericModalOpen } = useModalIdContext();
  const onlineSchedulingDispatch = useOnlineSchedulingDispatch();
  const { themeName } = useTheme();

  useEffect(() => {
    setIsGenericModalOpen(true);
    const dispatchAction: OnlineSchedulingDispatchAction = {
      type: OnlineSchedulingConstants.initialLeadData,
      result: { osDomain: window?.location?.hostname, leadData: values, isInline: true },
    };
    onlineSchedulingDispatch(dispatchAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!props.fields) {
    return <></>;
  }

  const modalSize = getEnum<ModalSize>(props.fields.modalSize) || 'extra-large';

  function closeModal() {
    const event = new Event(OnlineSchedulingConstants.events.closeModal);
    document.dispatchEvent(event);
    setIsGenericModalOpen(false);
    setIsModalOpen(false);
  }

  return (
    <>
      {isModalOpen &&
        ReactDOM.createPortal(
          <span ref={elementRef}>
            <ModalWrapper
              size={modalSize}
              isModalOpen={isModalOpen}
              handleClose={closeModal}
              closeIconWrapperClasses="absolute flex h-[40px] w-full justify-end"
              modalLabel={OnlineSchedulingConstants.osModalId}
            >
              <Placeholder name="osComponent" rendering={props} />
            </ModalWrapper>
          </span>,
          document.querySelector(`.${themeName}`) || document.body
        )}
    </>
  );
};

export default OnlineSchedulingInitInModal;
