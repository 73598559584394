// Lib
import { ThemeFile } from 'lib/context/ThemeContext';

export const ReviewsListTheme: ThemeFile = {
  aw: {
    classes: {},
  },
  rba: {
    classes: {
      reviewListWrapper: 'my-8',
      reviewList: 'border-t border-t-gray clear-both',
      review:
        'flex border-b border-b-gray sm:flex-col sm:px-m md:pr-0 sm:py-xs md:flex-row md:py-s',
      ratingSection: 'flex sm:flex-row md:w-[15%] md:flex-col md:justify-center md:pr-ml',
      ratingText:
        'font-sans font-medium sm:text-xs md:px-5 md:text-center md:text-xl md:leading-xl',
      ratingStars: 'sm:pl-xxxs md:ml-auto md:mr-auto md:max-w-fit md:pt-s sm:pt-[2px]',
      reviewSection: 'flex flex-col md:w-[60%] md:justify-center',
      reviewSectionNoProductImages: 'flex flex-col md:w-[85%] md:justify-center',
      reviewNameSection: 'sm:mt-xxxs font-sans',
      reviewName: 'font-medium md:text-xs md:leading-m',
      reviewLocation: 'font-normal text-dark-gray md:text-body md:leading-m',
      reviewDateSection: 'sm:mt-xxxs md:mt-xxxs',
      reviewDate: 'font-normal md:text-body md:leading-m',
      reviewText:
        'font-normal text-dark-gray sm:mt-xxxs md:mt-xxxs md:max-w-[592px] md:text-body md:leading-m',
      reviewTextNoProductImages:
        'font-normal text-dark-gray sm:mt-xxxs md:mt-xxxs md:text-body md:leading-m',
      productSection: 'flex flex-col sm:mt-xs md:justify-center md:text-xxs md:leading-s',
      productSectionHeader: 'font-bold',
      productSectionImages: 'flex flex-row sm:mt-xs',
      productSectionImageWrapper: 'sm: mr-ml md:mt-s md:mr-s',
      productSectionProductName: 'ml-auto mr-auto max-w-fit md:mt-xxxs',
      loadMoreContainer: 'max-w-fit ml-auto mr-auto mt-ml',
      loadMoreButton: 'border border-black p-s font-bold text-body',
      loadMoreText: 'mr-xxs',
    },
  },
};
