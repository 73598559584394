// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
import {
  withDatasourceCheck,
  Text,
  GetStaticComponentProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';

// Components
import { ReviewSummaryTheme } from './ReviewSummary.theme';
import { StarRating } from 'src/helpers/StarRating';
import { ProgressBar } from 'src/helpers/ProgressBar';

// Lib
import { getReviewsSummary, parseReviewFilterData } from 'lib/reviews/get-reviews';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import SvgIcon from 'src/helpers/SvgIcon/SvgIcon';
import { ReviewsList } from '../../../helpers/ReviewsList';
import { useReviewSummaryStore } from './reviewsummary.store';

export type ReviewSummaryProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.Reviews.ReviewSummaryAndList;
const ReviewSummary = (props: ReviewSummaryProps) => {
  const { fields, productReview } = props;
  const { themeData } = useTheme(ReviewSummaryTheme);
  const { currentScreenWidth } = useCurrentScreenType();

  const averageRating = productReview?.averageRating || '';
  const totalReviews = productReview?.totalReviews || '';
  const showReviewList = fields?.showReviewList?.value || false;

  const setExactRating = useReviewSummaryStore((store) => store.setExactRating);

  const ratingStars = (isMobile: boolean) => {
    const sortedRatings = productReview?.starRatings.sort(
      (a: { rating: number }, b: { rating: number }) => b.rating - a.rating
    );
    return sortedRatings?.map((starRating: any) => {
      const isComplete = starRating?.numberOfRatings === totalReviews;

      return (
        <>
          {isMobile ? (
            <div
              key={starRating?.rating}
              id={starRating?.rating}
              className={classNames(
                'items-center justify-items-center px-xxs py-[2px]',
                themeData.classes.ratingsIconsList
              )}
              onClick={handleRatingClick}
              data-rating={starRating?.rating}
            >
              {ratingStarItem(starRating, isComplete, '20')}
            </div>
          ) : (
            <div
              key={starRating?.rating}
              className={classNames(
                themeData.classes.ratingsIconsList,
                'items-center',
                'rating-button'
              )}
              onClick={handleRatingClick}
              data-rating={starRating?.rating}
            >
              {ratingStarItem(starRating, isComplete, '25')}
            </div>
          )}
        </>
      );
    });
  };

  const ratingStarItem = (starRating: any, isComplete: boolean, iconSize: string) => {
    return (
      <>
        <div>
          <StarRating
            reviewStars={starRating?.rating}
            iconSize={iconSize}
            containerCSSClass="ratingStars"
          />
        </div>
        <div className={themeData.classes.progressBarWrapper}>
          <ProgressBar
            percent={(Number(starRating?.numberOfRatings + 1) / (totalReviews + 1)) * 100}
            stepLabel={''}
            isComplete={isComplete}
            activeStep={starRating?.numberOfRatings + 1}
            totalSteps={totalReviews + 1}
            showSteps={false}
          />
        </div>
        <div className={classNames(themeData.classes.totalReviewsText)}>
          <Text tag="h5" field={{ value: starRating?.numberOfRatings.toLocaleString() }} />
        </div>
      </>
    );
  };

  const toggleDropdown = () => {
    const dropdown = document.querySelector('#dropdown');
    dropdown?.classList.toggle('hidden');
    const dropdownBtn = document.querySelector('#dropdownButton');
    dropdownBtn?.classList.toggle('rotate-180');
  };

  const handleRatingClick = (e: any) => {
    let clickedElement = e.target;
    while (!clickedElement.classList.contains('rating-button')) {
      clickedElement = clickedElement.parentElement;
    }
    setExactRating(clickedElement.getAttribute('data-rating') as number);
  };

  const isMobile = currentScreenWidth < getBreakpoint('md');
  const starSize = isMobile ? '24' : '32';

  return (
    <div
      data-component="reviews/reviewsummary"
      className="theme-white my-8 md:max-w-screen-lg lg:mx-auto"
    >
      <div className={themeData.classes?.summaryTitle}>{fields?.title?.value}</div>
      <div className="flex flex-col sm:ml-[22px] md:flex-row md:items-center md:gap-2 md:pl-s">
        <div className={themeData.classes.ratingsAndReviewCountWrapper}>
          <div className={themeData.classes.ratingText}>
            <Text
              tag="h2"
              field={{
                editable: parseFloat(averageRating).toFixed(1),
                value: parseFloat(averageRating).toFixed(1),
              }}
            />
          </div>
          <div className="flex flex-row gap-1 md:flex-col">
            <div>
              <StarRating
                reviewStars={averageRating}
                iconSize={starSize}
                containerCSSClass="ratingStars"
              />
            </div>

            <div className={classNames(themeData.classes.totalReviewsText)}>
              <h5>out of </h5>
              <Text tag="h5" field={{ value: totalReviews.toLocaleString() }} />
              <h5> reviews</h5>
            </div>
          </div>
          {currentScreenWidth < getBreakpoint('md') && (
            <div id="dropdownButton" onClick={() => toggleDropdown()}>
              <SvgIcon icon="dropdown-arrow" size="lg" />
            </div>
          )}
        </div>

        <div className={themeData.classes?.ratingsWrapper} id="dropdown">
          {currentScreenWidth < getBreakpoint('md') ? ratingStars(true) : ratingStars(false)}
        </div>
      </div>

      {showReviewList && (
        <div>
          <ReviewsList {...props} />
        </div>
      )}
    </div>
  );
};

/* istanbul ignore next - We aren't running E2E tests. */
export const getStaticProps: GetStaticComponentProps = async (rendering, layout, context) => {
  const datasource = rendering as ReviewSummaryProps;
  const productReviewStaticProps: { productReview?: any } = {};

  if (!datasource) {
    return productReviewStaticProps;
  }

  const filters = parseReviewFilterData({
    reviewFilterProps: datasource,
    context: context,
    layout: layout,
  });
  const reviewSummary = await getReviewsSummary(filters);
  productReviewStaticProps.productReview = reviewSummary;

  return productReviewStaticProps;
};

export default withDatasourceCheck()<ReviewSummaryProps>(ReviewSummary);
