export const customImageLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) => {
  // Fix for local docker.
  if (process.env.NEXT_PUBLIC_EW_ENVIRONMENT == 'Local' && src) {
    src = src?.replace('https://cm/', '/');
  }
  try {
    if (!src || typeof src !== 'string') {
      throw new Error('Invalid src provided');
    }
    src = src.replace(/^https:\/\/edge\.sitecorecloud\.io\/andersencorporation-[^/]+\//, '/-/');

    const defaultUrl = `/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`;
    return defaultUrl;
  } catch (error) {
    console.error('Error in customImageLoader:', error);
    throw error;
  }
};
