// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
// Components
import Headline from 'src/helpers/Headline/Headline';
import { Subheadline } from 'src/helpers/Subheadline';
import { BodyCopy } from 'src/helpers/BodyCopy';
import ImagePrimary from 'src/helpers/Media/ImagePrimary';
import classNames from 'classnames';
import { getEnum } from 'lib/utils';
import { Eyebrow } from 'src/helpers/Eyebrow';
import { Caption } from 'src/helpers/Caption';
import { Button } from 'src/helpers/Button';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Item } from '@sitecore-jss/sitecore-jss/layout';

export type PromoProps =
  Feature.EnterpriseWeb.Enterprise.Components.Tabs.TabsFeaturedPromo.TabContent & {
    classes: { [key: string]: string };
  };

const Promo = (props: PromoProps) => {
  const { fields, classes } = props;

  const { themeName } = useTheme();

  if (!fields) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(
          themeName === 'rba' && getEnum(fields.backgroundColor) === 'black'
            ? 'theme-black'
            : 'theme-gray',
          classes.promoContainer
        )}
      >
        <div className={classes.promoImageWrapper}>
          <ImagePrimary hideCaption={true} {...props} />
          {themeName === 'rba' && fields.primaryImageCaption.value && (
            <div className={classes.promoImageCaptionWrapper}>
              <div className={classes.promoImageCaption}>
                <Caption
                  italic={false}
                  isImageCaption={false}
                  caption={fields.primaryImageCaption}
                />
              </div>
            </div>
          )}
        </div>
        <div className={classes.promoContentWrapper}>
          {themeName === 'rba' && <Eyebrow classes={classes.promoEyebrow} fields={fields} />}
          <Headline classes={classes.promoHeadline} fields={fields} />
          <Subheadline classes={classes.promoSubheadline} fields={fields} />
          <BodyCopy classes={classes.promoBody} fields={fields} />
          <Button
            field={fields?.cta1Link as LinkField | undefined}
            variant={fields?.cta1Style as Item | undefined}
            icon={fields?.cta1Icon as Item | undefined}
            classes=""
          />
          {fields?.cta2Link ? (
            <Button
              field={fields?.cta2Link as LinkField | undefined}
              variant={fields?.cta2Style as Item | undefined}
              icon={fields?.cta2Icon as Item | undefined}
              classes="mt-[16px]"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Promo;
