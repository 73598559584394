// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { getEnum } from 'lib/utils';

// Components
import Component from 'src/helpers/Component/Component';
import { ComponentProps } from 'lib/types/component-props';
import { useRef, useState } from 'react';
import { SliderWrapper } from 'src/helpers/SliderWrapper';
import ImageWrapper from 'src/helpers/Media/ImageWrapper';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import classNames from 'classnames';
import { SliderRefType, SliderType } from 'src/helpers/SliderWrapper/SliderWrapper';
import { RichTextWrapper } from 'src/helpers/RichTextWrapper';
import {
  getScaledImageField,
  getScaledThumbnailFieldFromPhoto,
} from 'src/lib/utils/photo-item-utils';

export type GalleryWithFeaturedImageProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.General.GalleryWithFeaturedImage.GalleryWithFeaturedImage &
    ComponentProps;
const GalleryWithFeaturedImage = (props: GalleryWithFeaturedImageProps) => {
  const { currentScreenWidth } = useCurrentScreenType();
  const isMobile = currentScreenWidth <= getBreakpoint('md');

  const [selectedImageIndex, setselectedImageIndex] = useState(0);

  const sliderRef = useRef<SliderType>();
  const { fields } = props;

  if (!fields) {
    return <></>;
  }

  const sliderSettings = {
    className: 'galleryWithFeaturedImage',
    enableNumberedPagination: true,
    rows: 3,
    slidesPerRow: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: getBreakpoint('md'),
        settings: {
          rows: 1,
          slidesPerRow: 1,
          slidesToShow: 3.4,
        },
      },
    ],
  };

  const getSpacingClasses = (index: number): string => {
    type ColumnIndices = 0 | 1 | 2;

    const maxItemsPerRow = 3;

    const columnWiseClasses: Record<ColumnIndices, string> = {
      0: 'md:pr-[10px]',
      1: 'md:px-[5px]',
      2: 'md:pl-[10px]',
    };

    return columnWiseClasses[(index % maxItemsPerRow) as ColumnIndices];
  };

  const removeImageSizeParameters = (url: string): string => {
    try {
      const modifiedURL = new URL(url);
      modifiedURL.searchParams.delete('w');
      modifiedURL.searchParams.delete('h');
      return modifiedURL.toString();
    } catch {
      return url;
    }
  };

  type GalleryWithFeaturedImageThumbnailProps = {
    index: number;
    image: Feature.EnterpriseWeb.RenewalByAndersen.Data.Photos.Photo;
  };

  const RenderThumbnailElement = ({ index, image }: GalleryWithFeaturedImageThumbnailProps) => {
    if (!image.fields?.fullImage?.value?.src) {
      return <></>;
    }

    const hasThumbnail = !!image.fields?.thumbnailImage?.value?.src;
    const thumbnailFocusArea = getEnum<string>(image?.fields?.thumbnailFocusArea);
    const useThumbnailFocusArea = !!thumbnailFocusArea && !hasThumbnail;
    const scaledThumbnail = getScaledThumbnailFieldFromPhoto(image);
    const imageLayout = useThumbnailFocusArea ? 'fill' : 'responsive';
    const ratio = useThumbnailFocusArea ? 'square' : undefined;

    return (
      <ImageWrapper
        key={index}
        image={{
          value: {
            src: scaledThumbnail?.value?.src,
            width: isMobile ? 100 : 187,
            height: isMobile ? 100 : 187,
            alt: image?.fields?.fullImage?.value?.alt,
          },
        }}
        imageLayout={imageLayout}
        focusArea={useThumbnailFocusArea ? thumbnailFocusArea : undefined}
        alwaysUseFocusArea={useThumbnailFocusArea}
        ratio={ratio}
        additionalMobileClasses={'aspect-square'}
      />
    );
  };

  const defaultFeaturedImageDimension = 592;
  const thumbnailFocusArea = getEnum<string>(
    fields?.images[selectedImageIndex]?.fields?.thumbnailFocusArea
  );
  const hasThumbnail = fields?.images[selectedImageIndex]?.fields?.thumbnailImage?.value?.src;

  // Only use the thumbnail focus area if the thumbnail is not set.
  const useThumbnailFocusArea = !!thumbnailFocusArea && !hasThumbnail;

  // If the thumbnail focus area is set and the viewport is not mobile, use the fill layout.
  // Otherwise use the intrinsic layout so that the image is not cropped.
  const fullImageLayout = useThumbnailFocusArea && !isMobile ? 'fill' : 'intrinsic';

  // If the thumbnail focus area is set, use the square ratio so that the image is cropped to the focus area.
  const fullImageRatio = useThumbnailFocusArea ? 'square' : undefined;
  const additionalDesktopClasses = useThumbnailFocusArea
    ? 'aspect-square md:[&_img]:object-contain'
    : undefined;

  const scaledImage = getScaledImageField(
    fields?.images[selectedImageIndex]?.fields?.fullImage,
    defaultFeaturedImageDimension
  );

  // If the viewport is mobile in size, use the entire width.
  // Otherwise if the thumbnail focus area is set, use the width of the scaled image.
  // If the thumbnail focus is not set, then use the default width.
  const featuredImageWidth = isMobile
    ? currentScreenWidth
    : useThumbnailFocusArea
    ? scaledImage?.value?.width ?? defaultFeaturedImageDimension
    : defaultFeaturedImageDimension;

  // If the viewport is mobile in size, use 375px as the height.
  // Otherwise if the thumbnail focus area is set, use the height of the scaled image.
  // If the thumbnail focus is not set, then use the default height.
  const featuredImageHeight = isMobile
    ? 375
    : useThumbnailFocusArea
    ? scaledImage?.value?.height ?? defaultFeaturedImageDimension
    : defaultFeaturedImageDimension;

  return (
    <Component
      variant={currentScreenWidth <= getBreakpoint('md') ? 'full' : 'lg'}
      padding={currentScreenWidth <= getBreakpoint('md') && 'px-0'}
      dataComponent="general/gallerywithfeaturedimage"
      {...props}
    >
      <div className="col-span-12 md:col-span-6">
        <div className="relative">
          <ImageWrapper
            image={{
              value: {
                src: removeImageSizeParameters(
                  fields?.images[selectedImageIndex]?.fields?.fullImage?.value?.src
                ),
                width: featuredImageWidth,
                height: featuredImageHeight,
                alt: fields?.images[selectedImageIndex]?.fields?.fullImage?.value?.alt,
              },
            }}
            imageLayout={fullImageLayout}
            alwaysUseFocusArea={useThumbnailFocusArea}
            focusArea={useThumbnailFocusArea ? thumbnailFocusArea : undefined}
            ratio={fullImageRatio}
            additionalDesktopClasses={additionalDesktopClasses}
          />
        </div>
        <RichTextWrapper
          classes="px-m md:px-0"
          field={fields?.images[selectedImageIndex]?.fields?.photoCaption}
        />
      </div>
      <div className="col-span-12 md:col-span-6 md:-mb-s">
        <div
          className={classNames(
            'md:[&_.numbered-pagination_ul]:mx-m md:[&_.numbered-pagination_ul]:text-right'
          )}
        >
          <SliderWrapper sliderSettings={sliderSettings} sliderRef={sliderRef as SliderRefType}>
            {fields?.images.map(
              (image: Feature.EnterpriseWeb.RenewalByAndersen.Data.Photos.Photo, index: number) => (
                <div
                  key={index}
                  className={classNames(
                    'pb-[15px]',
                    isMobile && 'mx-m pr-xxs',
                    getSpacingClasses(index),
                    'align-bottom'
                  )}
                >
                  <div
                    className={classNames(
                      index === selectedImageIndex &&
                        'relative before:absolute before:top-0 before:left-0 before:z-10 before:h-full before:w-full before:border-[4px] before:border-primary'
                    )}
                    onClick={() => setselectedImageIndex(index)}
                  >
                    <RenderThumbnailElement index={index} image={image}></RenderThumbnailElement>
                  </div>
                </div>
              )
            )}
          </SliderWrapper>
        </div>
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<GalleryWithFeaturedImageProps>(GalleryWithFeaturedImage);
