// Lib
import { ThemeFile } from 'lib/context/ThemeContext';

export const ThreekitVisualizerTheme: ThemeFile = {
  aw: {
    classes: {
      body: 'body-copy col-span-12 [&_*]:max-w-full',
    },
  },
  rba: {
    classes: {
      body: 'body-copy col-span-12 [&_*]:max-w-full',
    },
  },
};
