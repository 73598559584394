const FIELD_IDS = {
  // Address Information
  ADDRESS1: 'street',
  ADDRESS2: '00N500000020bSO',
  CITY: 'city',
  STATE: 'state',
  ZIPCODE: 'zip',
  COUNTRY: 'country',

  // Contact Information
  FIRSTNAME: 'first_name',
  LASTNAME: 'last_name',
  TITLE: 'title',
  COMPANYNAME: 'company',
  COMPANYWEBSITE: 'url',
  EMAIL: 'email',
  PHONE: 'phone',
  MOBILE: 'mobile',
  PREFERREDMETHOD: '00N50000002affh',

  // Request Information
  QUESTIONCOMMENT: '00N500000020bSa',

  // Sourcing Information
  LEADSOURCE: 'lead_source',
  ORIGINALSOURCE: '00N50000003AXfW',

  // Project Information
  PROJECTSPERYEAR: '00N500000020bSZ',
  PROJECTTIMING: '00N500000020bSX',
  PROJECTTYPE: '00N5000000212BV',

  // Design Tool
  DESIGNSPECS: '00N50000003ezR5',
  DESIGNTOOLSERIES: '00N50000003isvV',

  // System Fields
  PROCESSSTATE: '00N50000001zQ43',
  SESSIONDATA: '00N2J00000AiANZ',
  INTERACTIONDATE: 'interaction_date',

  // Account Information
  ORGID: 'oid',
  CAMPAIGNIDS: '00N500000020bSH',
  SELECTEDUSERTYPE: '00N500000020bSh',
  USERTYPE: '00N500000020bSh',
  RECORDTYPE: 'recordType',
  HOMEOWNER: '012500000009cM2',
  DEALER: '012500000009cM1',
  TRADEPROFESSIONAL: '012500000009cM3',
  SELECTEDTRADEPROTYPE: 'trade_pro_type',
  // Resource Request
  CONTACTME: '00N500000020bSI',
  LITERATUREREQUESTED: '00N500000020bSK',
  NEWSLETTER: '00NHt00000B9x2h',
  SMSOPTINID: '00N2J00000AiCBB',

  // Request a Quote
  WORKINGWITHDEALER: '00N50000002affX',
  DEALERNAME: '00N2J000007TMo7',
  DEALERID: '00N50000002affc',
  ESTIMATEDWINDOWS: '00N50000003ezR7',
  ESTIMATEDDOORS: '00N50000003ezR6',
  VISITSHOWROOM: '00N50000003ezRC',
  INSTALLATIONSERVICES: '00N50000003ezR9',
  FINANCING: '00N50000003ezR8',
  PREFERREDPRODUCTSERIES: '00N50000003ezRA',
  PROJECTNAMEANDLOCATION: '00N50000003ezRB',
  CURRENTLYBUYSFROM: '00N5000000A46Nv',
  NUMBEROFEMPLOYEES: '00N2J00000AKkEa',

  // Certified Contractor
  YEARSINBUSINESS: '00N50000001yl42',
  COVERAGEAREA: '00N2J00000AdjCx',
  TOTALBUSINESSWINDOWSANDDOORS: '00N2J00000AdjCz',
  TOTALBUSINESSHOMEIMPROVEMENTPROJECTS: '00N2J00000AdjCy',
  HOWOFTENUSEAW: '00N500000020bSJ',
  ASSOCIATIONSANDACCREDITATIONS: '00N2J00000AdjCw',
  WINDOWBUSINESSCLADPRODUCTS: '00N2J00000AdjD0',
  ANNUALSALES: '00N2J00000AdjCv',
  THDSALESREP: '00N2J00000AOYD8',
};

export default FIELD_IDS;
