// using contentBlockWithMedia as a base
import Component from 'src/helpers/Component/Component';
import Headline from 'src/helpers/Headline/Headline';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextWrapper from 'src/helpers/RichTextWrapper/RichTextWrapper';
import ImageWrapper from 'src/helpers/Media/ImageWrapper';
import MediaPrimary from 'src/helpers/Media/MediaPrimary';
import MediaSecondary from 'src/helpers/Media/MediaSecondary';
import SvgIcon from 'src/helpers/SvgIcon/SvgIcon';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
import { ContentBlockWithMediaAndProductTheme } from './ContentBlockWithMediaAndProduct.theme';
import { useExperienceEditor } from 'lib/utils';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import { Button, cta1ToButtonProps, cta2ToButtonProps } from 'src/helpers/Button';
import ButtonGroup from 'src/helpers/ButtonGroup/ButtonGroup';
import { Item } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import ModalWrapper from 'src/helpers/ModalWrapper/ModalWrapper';
import { SliderWrapper } from 'src/helpers/SliderWrapper';
import React from 'react';
import TagManager from 'react-gtm-module';
import ModalPortal from './ModalPortal';

export type ContentBlockWithMediaAndProductProps =
  Feature.EnterpriseWeb.Enterprise.Components.General.ContentBlockWithMedia.ContentBlockWithMedia;
const ContentBlockWithMediaAndProduct = (
  props: ContentBlockWithMediaAndProductProps
): JSX.Element => {
  const productSeries = props?.fields?.['product Series'];
  const [selectedProductIndex, setSelectedProductIndex] = useState<number | null>(null);

  //for modal impression tracking
  useEffect(() => {
    if (selectedProductIndex !== null) {
      const selectedProduct = productSeries[selectedProductIndex];
      TagManager.dataLayer({
        dataLayer: {
          event: 'blog_modal_impression',
          modalName: `modal-${selectedProductIndex}`,
          modalTitle: selectedProduct.name, // Using product name as modal title
          pageType: 'blog',
        },
      });
    }
  }, [productSeries, selectedProductIndex]);

  const reqQouteCta = {
    href: '/request-a-quote/',
    text: 'Request a Quote',
    anchor: '',
    linktype: 'internal',
    class: '',
    title: '',
    querystring: '',
    id: '{533E1D13-366B-4F6F-B650-958D8D49137C}',
  };

  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <div
        className="absolute -bottom-12 right-[34%] z-10 flex h-ml w-ml translate-x-0 -translate-y-1/2 transform items-center justify-center rounded-full border border-secondary"
        onClick={onClick}
      >
        <SvgIcon className="" size="md" icon="arrow-right" />
      </div>
    );
  }

  function PrevArrow(props: any) {
    const { onClick } = props;
    return (
      <div
        className="absolute -bottom-12 left-[34%] z-10 flex h-ml w-ml translate-x-0 -translate-y-1/2 transform items-center justify-center rounded-full border border-secondary"
        onClick={onClick}
      >
        <SvgIcon className="" size="md" icon="arrow-left" />
      </div>
    );
  }
  const sliderSettings = {
    dots: true,
    slidesToShow: 1,
    pauseOnFocus: true,
    pauseOnHover: true,
    dotsClass: 'slick-dots static',
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_oldIndex: number, newIndex: number) => {
      const activeSlide = document.querySelector(
        `.slick-slide[data-index="${newIndex}"]`
      ) as HTMLElement;
      if (activeSlide) {
        activeSlide.style.height = 'fit-content';
      }
    },
  };
  const isEE = useExperienceEditor();
  const { themeData } = useTheme(ContentBlockWithMediaAndProductTheme(props));
  const { currentScreenWidth } = useCurrentScreenType();
  const handleClick = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    // Prevents the default action of the event
    e.preventDefault();
    // tracking event for learn more products in the blog page
    TagManager.dataLayer({
      dataLayer: {
        event: 'blog_learnMore_click',
        productIndex: index,
        productName:
          productSeries?.[index]?.fields?.productFullName?.value ||
          productSeries?.[index]?.fields?.seriesTitle?.value,
        pageType: 'blog',
      },
    });
    setSelectedProductIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedProductIndex(null);
  };
  if (!props) {
    return <></>;
  }
  const imageLayoutType =
    props.fields?.primaryImage?.value?.src &&
    !props.fields?.secondaryImage?.value?.src &&
    currentScreenWidth >= getBreakpoint('md')
      ? 'intrinsic'
      : 'responsive';

  const imageContainerWidth =
    imageLayoutType === 'intrinsic'
      ? { maxWidth: `${props.fields?.primaryImage?.value?.width}px` }
      : {};

  const focusArea = 'top center';

  return (
    <Component variant="lg" dataComponent="general/contentblockwithmediandproduct" {...props}>
      <div className="col-span-12 grid grid-cols-12 md:gap-x-s">
        <div className={themeData.classes.headingContainer}>
          <Headline defaultTag="h2" classes={themeData.classes.headlineContainer} {...props} />
          <RichTextWrapper
            field={props.fields?.topCopy}
            className={themeData.classes.topCopyContainer}
          />
        </div>
        <div className={themeData.classes.bodyContainer}>
          <div className={themeData.classes.imageContainer}>
            {(props.fields?.primaryImage?.value?.src || props.fields?.primaryVideo || isEE) && (
              <div
                className={
                  props.fields?.secondaryImage?.value?.src
                    ? themeData.classes.imageOuterContainer
                    : themeData.classes.imageOuterContainerSecond
                }
                style={imageContainerWidth}
              >
                <MediaPrimary {...props} imageLayout={imageLayoutType} focusArea={focusArea} />
              </div>
            )}
            {(props.fields?.secondaryImage?.value?.src || props.fields?.secondaryVideo || isEE) && (
              <div className={themeData.classes.imageOuterContainer} style={imageContainerWidth}>
                <MediaSecondary {...props} imageLayout={imageLayoutType} focusArea={focusArea} />
              </div>
            )}

            {/* SliderWrapper for mobile view */}
            {productSeries ? (
              <div className="mobile block pb-10 md:hidden">
                <RichTextWrapper
                  defaultTag="h2"
                  classes={'text-s md:text-s font-sans text-center font-bold pb-8 '}
                  field={{ value: 'Products Shown' }}
                />
                <SliderWrapper sliderSettings={sliderSettings}>
                  {productSeries?.map((product: any, index: number) => {
                    return (
                      <div key={index} className="flex h-full flex-col">
                        <ImageWrapper
                          image={product?.fields?.productImage || product?.fields?.seriesImage}
                          additionalMobileClasses="w-52 mx-auto pb-s"
                          ratio="square"
                        />
                        <RichTextWrapper
                          field={{
                            value:
                              product?.fields?.productFullName?.value ||
                              product?.fields?.seriesTitle?.value,
                          }}
                          classes={themeData.classes.productDescriptionMobile}
                        />
                        <div onClick={(e) => handleClick(e, index)} className="flex justify-center">
                          <Button
                            variant={
                              {
                                fields: {
                                  Value: {
                                    value: 'primary',
                                  },
                                },
                              } as unknown as Item
                            }
                            field={{ value: { text: 'Learn More' } }}
                            classes=" md:!w-fit justify-center w-[200px] pb-2.5"
                          />
                        </div>
                      </div>
                    );
                  })}
                </SliderWrapper>
              </div>
            ) : (
              <> </>
            )}
            {/* SliderWrapper for mobile view */}
            {productSeries ? (
              <div className="col-span-2 hidden md:block">
                <RichTextWrapper
                  defaultTag="h2"
                  classes={themeData.classes.headlineContainerProductUsed}
                  field={{ value: 'Products used in this image' }}
                />
                <div>
                  {productSeries?.map((product: any, index: number) => {
                    const descriptionButtonProps =
                      product?.fields?.seriesLandingPageCTA ||
                      product?.fields?.productDetailPageLink;
                    const designToolCTA = product?.fields?.designToolLink?.value;
                    const secondCTA = product?.fields?.designToolLink?.value || reqQouteCta;
                    return (
                      <div key={index} className="flex flex-col items-center">
                        <ImageWrapper
                          image={product?.fields?.productImage || product?.fields?.seriesImage}
                          additionalDesktopClasses=" pb-s md:w-[76%] lg:w-fit lg:h-[154px] md:h-[76%]" //
                          ratio="square"
                          imageLayout="fill"
                        />
                        <RichTextWrapper
                          field={{
                            value:
                              product?.fields?.productFullName?.value ||
                              product?.fields?.seriesTitle?.value,
                          }}
                          classes={themeData.classes.productDescription}
                        />
                        <div className="self-center pb-8" onClick={(e) => handleClick(e, index)}>
                          <Button
                            variant={
                              {
                                fields: {
                                  Value: {
                                    value: 'primary',
                                  },
                                },
                              } as unknown as Item
                            }
                            field={{ value: { text: 'Learn More' } }}
                            classes="!w-full md:!w-fit justify-center pb-2.5 lg:text-[1rem] md:text-[0.95rem]"
                          />
                        </div>
                        <ModalPortal>
                          {/* {createPortal( */}
                          <ModalWrapper
                            size="fluid"
                            handleClose={handleCloseModal}
                            isModalOpen={selectedProductIndex === index}
                            customOverlayclass=""
                            customContentWrapperclass="md:w-[93vw] lg:w-[50vw] w-[93vw] !bg-white"
                          >
                            <div className="grid grid-cols-12 pb-10 md:gap-x-s">
                              <div className="col-span-12 md:col-span-3 lg:col-span-3">
                                <div className="ml-3">
                                  <ImageWrapper
                                    image={
                                      product?.fields?.productImage || product?.fields?.seriesImage
                                    }
                                    additionalDesktopClasses="md:w-52 pl-4 "
                                    ratio="square"
                                    imageLayout="fill"
                                  />
                                </div>
                              </div>
                              <div className="col-span-12 md:col-span-9 lg:col-span-9 lg:mr-14">
                                <div className="mr-3 sm:ml-16  lg:mr-0">
                                  <RichTextWrapper
                                    field={{
                                      value:
                                        product?.fields?.productFullName?.value ||
                                        product?.fields?.seriesTitle?.value,
                                    }}
                                    classes={themeData.classes.headlineContainer}
                                  />
                                  <RichTextWrapper
                                    field={{
                                      value:
                                        product?.fields?.seriesSubtitle?.value ||
                                        product?.fields?.productSubtitle?.value,
                                    }}
                                    classes={themeData.classes.topCopySubtitle}
                                  />
                                  <RichTextWrapper
                                    field={
                                      product?.fields?.productDescription ||
                                      product?.fields?.seriesDescription
                                    }
                                    classes={themeData.classes.topCopyContainer}
                                  />
                                  <div className="mt-4 flex space-y-4 md:space-x-4 md:space-y-0 lg:flex-row lg:space-y-0 lg:space-x-4 ">
                                    {descriptionButtonProps && (
                                      <div
                                        onClick={() => {
                                          TagManager.dataLayer({
                                            dataLayer: {
                                              event: 'blog_modal_exploreProduct',
                                              productIndex: index,
                                              productName:
                                                product?.fields?.productFullName?.value ||
                                                product?.fields?.seriesTitle?.value,
                                              pageType: 'blog',
                                            },
                                          });
                                        }}
                                      >
                                        <Button
                                          variant={
                                            {
                                              fields: {
                                                Value: {
                                                  value: 'primary',
                                                },
                                              },
                                            } as unknown as Item
                                          }
                                          field={descriptionButtonProps}
                                          classes="!w-full md:!w-fit justify-center"
                                        />
                                      </div>
                                    )}
                                    {secondCTA && (
                                      <div
                                        onClick={() => {
                                          TagManager.dataLayer({
                                            dataLayer: {
                                              event: designToolCTA
                                                ? 'blog_modal_designYourOwn'
                                                : 'blog_modal_requestQuote',
                                              productIndex: index,
                                              productName:
                                                product?.fields?.productFullName?.value ||
                                                product?.fields?.seriesTitle?.value,
                                              pageType: 'blog',
                                            },
                                          });
                                        }}
                                      >
                                        <Button
                                          variant={
                                            {
                                              fields: {
                                                Value: {
                                                  value: 'secondary',
                                                },
                                              },
                                            } as unknown as Item
                                          }
                                          field={secondCTA}
                                          classes="!w-full md:!w-fit justify-center"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ModalWrapper>
                        </ModalPortal>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <> </>
            )}
          </div>
          <RichTextWrapper
            classes={themeData.classes.bodyContainer}
            field={props.fields?.bottomCopy}
          />
        </div>
        <div className={themeData.classes.contentWrapper}>
          <div
            className={
              props.fields.ctaAlignment?.displayName == 'Stack'
                ? 'flex flex-col items-start justify-evenly md:space-y-4'
                : 'flex flex-col items-start md:flex-row'
            }
          >
            <ButtonGroup
              cta1={cta1ToButtonProps(props, themeData.classes.buttonGroupClass.cta1Classes)}
              cta2={cta2ToButtonProps(props, themeData.classes.buttonGroupClass.cta2Classes)}
              wrapperClasses={themeData.classes.buttonGroupClass.wrapper}
            />
          </div>
        </div>
        <div className="col-span-12">
          {/* ModalWrapper placed separately outside the SliderWrapper for mobile */}
          {productSeries?.map((product: any, index: number) => {
            const descriptionButtonProps =
              product?.fields?.seriesLandingPageCTA || product?.fields?.productDetailPageLink;
            const designToolCTA = product?.fields?.designToolLink?.value;
            const secondCTA = product?.fields?.designToolLink?.value || reqQouteCta;
            return (
              // eslint-disable-next-line react/jsx-key
              <ModalPortal>
                <div key={index} className="mobile-modal block md:hidden">
                  <ModalWrapper
                    key={`modal-${index}`}
                    size="extra-large"
                    handleClose={handleCloseModal}
                    isModalOpen={selectedProductIndex === index}
                    customOverlayclass=""
                    customContentWrapperclass="!size-full pb-m"
                  >
                    <div className="mt-16 grid grid-cols-12 pb-10  md:gap-x-s">
                      <div className="col-span-12 lg:col-span-5">
                        <div className="ml-3 mr-3 lg:ml-0">
                          <ImageWrapper
                            image={product?.fields?.productImage || product?.fields?.seriesImage}
                            additionalMobileClasses="w-40 mx-auto pb-s"
                            ratio="square"
                            imageLayout="intrinsic"
                          />
                        </div>
                      </div>
                      <div className="col-span-12 lg:col-span-7">
                        <div className="mr-3 sm:ml-3 sm:mr-3 lg:mr-0">
                          <RichTextWrapper
                            field={{
                              value:
                                product?.fields?.productFullName?.value ||
                                product?.fields?.seriesTitle?.value,
                            }}
                            classes={themeData.classes.headlineContainerMobile}
                          />
                          <RichTextWrapper
                            field={{
                              value:
                                product?.fields?.seriesSubtitle?.value ||
                                product?.fields?.productSubtitle?.value,
                            }}
                            classes={themeData.classes.topCopySubtitle}
                          />
                          <RichTextWrapper
                            field={
                              product?.fields?.productDescription ||
                              product?.fields?.seriesDescription
                            }
                            classes={themeData.classes.topCopyContainer}
                          />

                          <div className="mt-4 flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                            {descriptionButtonProps && (
                              <div
                                onClick={() => {
                                  TagManager.dataLayer({
                                    dataLayer: {
                                      event: 'blog_modal_exploreProduct',
                                      productIndex: index,
                                      productName:
                                        product?.fields?.productFullName?.value ||
                                        product?.fields?.seriesTitle?.value,
                                      pageType: 'blog',
                                    },
                                  });
                                }}
                              >
                                <Button
                                  variant={
                                    {
                                      fields: {
                                        Value: {
                                          value: 'primary',
                                        },
                                      },
                                    } as unknown as Item
                                  }
                                  field={descriptionButtonProps}
                                  classes=" md:!w-fit justify-center w-[200px]"
                                />
                              </div>
                            )}
                            {secondCTA && (
                              <div
                                onClick={() => {
                                  TagManager.dataLayer({
                                    dataLayer: {
                                      event: designToolCTA
                                        ? 'blog_modal_designYourOwn'
                                        : 'blog_modal_requestQuote',
                                      productIndex: index,
                                      productName:
                                        product?.fields?.productFullName?.value ||
                                        product?.fields?.seriesTitle?.value,
                                      pageType: 'blog',
                                    },
                                  });
                                }}
                              >
                                <Button
                                  variant={
                                    {
                                      fields: {
                                        Value: {
                                          value: 'secondary',
                                        },
                                      },
                                    } as unknown as Item
                                  }
                                  field={secondCTA}
                                  classes=" md:!w-fit justify-center w-[200px]"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalWrapper>
                </div>
              </ModalPortal>
            );
          })}
        </div>
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<ContentBlockWithMediaAndProductProps>(
  ContentBlockWithMediaAndProduct
);
