import { ThemeFile } from 'lib/context/ThemeContext';
import { ContentBlockWithMediaProps } from './ContentBlockWithMedia';
import classNames from 'classnames';
import { ButtonVariants } from 'src/helpers/Button';
import { getEnum } from 'lib/utils';

const getImageContainerClasses = (props: ContentBlockWithMediaProps): string => {
  const hasPrimaryMedia = !!props.fields?.primaryImage?.value?.src || !!props.fields?.primaryVideo;
  const hasSecondaryMedia =
    !!props.fields?.secondaryImage?.value?.src || !!props.fields?.secondaryVideo;

  return hasPrimaryMedia && hasSecondaryMedia
    ? 'grid grid-cols-1 md:grid-cols-2 md:gap-s'
    : 'col-span-12';
};

const getImageOuterContainerClasses = (props: ContentBlockWithMediaProps): string => {
  return props.fields?.primaryImage?.value?.src && !props.fields?.secondaryImage?.value?.src
    ? classNames(`mx-auto text-center`)
    : '';
};

const getCTAPadding = (ctaStyle: any) => {
  let padding = '';
  if (getEnum<ButtonVariants>(ctaStyle) === 'link') {
    padding = 'ml-0 px-s md:px-0';
  } else if (getEnum<ButtonVariants>(ctaStyle) === 'link-right-icon') {
    padding = 'ml-0 px-s md:px-0 !pr-[50px]';
  }
  return padding;
};

export const ContentBlockWithMediaTheme = (
  props: ContentBlockWithMediaProps,
  fields: any
): ThemeFile => {
  return {
    aw: {
      classes: {
        headingContainer: 'col-span-12',
        headlineContainer: 'text-s md:text-m font-heavy pb-xxs md:pb-s',
        topCopyContainer: 'pb-s font-regular font-serif text-theme-body',
        bodyContainer: 'col-span-12 font-regular font-serif mb-s text-theme-body',
        imageOuterContainer: classNames('mb-m', getImageOuterContainerClasses(props)),
        imageContainer: getImageContainerClasses(props),
        videoOuterContainer: 'w-full relative',
        captionContainer: 'mt-xxs italic text-left mb-s font-sans text-sm-xxs md:text-caption',
        contentWrapper: classNames('col-span-12', 'md:w-full', 'md:max-w-screen-lg', 'md:mx-auto'),
        buttonGroupClass: {
          wrapper: 'flex-col w-fit',
          cta1Classes: classNames(getCTAPadding(fields?.cta1Style), 'mb-m md:mb-0 w-auto'),
          cta2Classes: classNames(getCTAPadding(fields?.cta2Style), 'mb-m md:mb-0 w-auto'),
          cta3Classes: classNames(getCTAPadding(fields?.cta3Style), 'mb-m md:mb-0 w-auto'),
        },
      },
    },
    rba: {
      classes: {
        headingContainer: 'col-span-12',
        headlineContainer: 'text-theme-text text-sm-m md:text-m font-medium mb-s',
        topCopyContainer: 'mb-s font-regular font-serif text-theme-body',
        bodyContainer: 'col-span-12 font-regular font-serif mb-s text-theme-body',
        imageOuterContainer: classNames('mb-s', getImageOuterContainerClasses(props)),
        imageContainer: getImageContainerClasses(props),
        videoOuterContainer: 'w-full relative',
        captionContainer:
          'mt-xxs md:mt-xxxs border-primary border-l-2 pl-xxs text-left mb-s text-sm-xxs md:text-body',
        contentWrapper: classNames('col-span-12', 'md:w-full', 'md:max-w-screen-lg', 'md:mx-auto'),
        buttonGroupClass: {
          wrapper: 'flex-col w-fit',
          cta1Classes: classNames(getCTAPadding(fields?.cta1Style), 'mb-m md:mb-0 w-auto'),
          cta2Classes: classNames(
            getCTAPadding(fields?.cta2Style),
            'mb-m md:mb-0 w-auto md:grow-0'
          ),
          cta3Classes: classNames(getCTAPadding(fields?.cta3Style), 'mb-m md:mb-0 w-auto'),
        },
      },
    },
  };
};
