import { FormFieldProps } from 'lib/forms';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { getEnum } from 'lib/utils';
import { useContext, useEffect } from 'react';
import { FormsContext } from 'lib/forms/FormContext';
import { FormikValues, useFormikContext } from 'formik';
import { sendMessageToParentPage } from 'lib/forms/OnlineScheduling/OnlineSchedulingUtils';
import { getFieldData } from 'lib/forms/FormFieldUtils';
import { useOnlineScheduling } from 'lib/forms/OnlineScheduling/OnlineSchedulingContext';
import TagManager from 'react-gtm-module';
import { OnlineSchedulingConstants } from 'lib/constants/online-scheduling';

export type SendMessageProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Forms.Events.SendMessageToParent & FormFieldProps;

const SendMessageToParent = (props: SendMessageProps): JSX.Element => {
  const { pageIndex, formProps } = useContext(FormsContext);
  const { values } = useFormikContext<FormikValues>();
  const { iframeHostDomain } = useOnlineScheduling();

  useEffect(() => {
    const messageData = {
      type: getEnum<string>(props?.fields?.messageType),
      description: props?.fields?.messageDescription?.value,
      pageIndex: pageIndex,
      leadData: getFieldData(formProps, values),
    };
    sendMessageToParentPage(messageData, iframeHostDomain);

    if (props?.fields?.gtmEvent?.value) {
      TagManager.dataLayer({
        dataLayer: {
          event: getEnum<string>(props?.fields?.messageType),
          description: props?.fields?.messageDescription?.value,
          sender: messageData?.leadData?.sender,
          pageIndex: pageIndex,
        },
      });
    }

    const osModal = document.getElementById(OnlineSchedulingConstants.osModalId);
    if (!!osModal) {
      const scrollableElement = Array.from(osModal.querySelectorAll('*')).find(
        (e) => e.scrollTop > 0 || e.scrollHeight > e.clientHeight
      );

      scrollableElement?.scrollTo(0, 0);
    }
    // run when the pageIndex gets updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  return <></>;
};

export default SendMessageToParent;
