import Image from 'next/image';
import awning from '../../assets/img/reviews/window-awning.svg';
import casement from '../../assets/img/reviews/window-casement.svg';
import doubleHung from '../../assets/img/reviews/window-double-hung.svg';
import picture from '../../assets/img/reviews/window-picture.svg';
import projection from '../../assets/img/reviews/window-projection.svg';
import sliding from '../../assets/img/reviews/window-sliding.svg';
import specialty from '../../assets/img/reviews/window-specialty.svg';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import { useTheme } from 'lib/context/ThemeContext';
import { ReviewProductImageTheme } from './ReviewProductImage.Theme';

export type ReviewProductImageProps = {
  productName: string;
};

const ReviewProductImage = (props: ReviewProductImageProps) => {
  const { themeData } = useTheme(ReviewProductImageTheme);
  const { productName } = props;
  const { currentScreenWidth } = useCurrentScreenType();

  const isMobile = currentScreenWidth < getBreakpoint('md');
  const imageSize = isMobile ? 60 : 85;

  const getProductImage = () => {
    switch (productName) {
      case 'awning':
        return awning;
      case 'casement':
        return casement;
      case 'double_hung':
        return doubleHung;
      case 'picture':
        return picture;
      case 'projection':
        return projection;
      case 'sliding':
        return sliding;
      case 'specialty':
        return specialty;
      default:
        return '';
    }
  };

  return (
    <div className={themeData.classes.productImage}>
      <Image src={getProductImage()} alt={productName} width={imageSize} height={imageSize} />
    </div>
  );
};

export default ReviewProductImage;
