// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
//import { useTheme } from 'lib/context/ThemeContext';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import Component from 'src/helpers/Component/Component';
import Script from 'next/script';
import Head from 'next/head';
import { getEnum } from 'lib/utils';
import { ThreekitVisualizerTheme } from './ThreekitVisualizer.theme';
import classNames from 'classnames';
import { useTheme } from 'lib/context/ThemeContext';

export type ThreekitVisualizerProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.Product.ThreekitVisualizer;
const ThreekitVisualizer = (props: ThreekitVisualizerProps) => {
  const { themeData } = useTheme(ThreekitVisualizerTheme);

  if (!props.fields) {
    return <></>;
  }
  const productId = getEnum<string>(props.fields.productId);
  return (
    <>
      <Head>
        <link href={props.fields.cssHref?.value} rel="stylesheet" />
      </Head>
      <Script src={props.fields.scriptSrc?.value} />
      <Component variant="lg" dataComponent="product/threekitvisualizer" {...props}>
        {productId && <input type="hidden" id="productId" value={productId} />}
        <div
          className={classNames(themeData.classes.body, 'tk-treble-app')}
          id="tk-treble-root"
        ></div>
      </Component>
    </>
  );
};

export default withDatasourceCheck()<ThreekitVisualizerProps>(ThreekitVisualizer);
