import React, { useEffect, useState } from 'react';
import { withDatasourceCheck, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import classNames from 'classnames';
import { getEnum } from 'lib/utils';
import Component from 'src/helpers/Component/Component';
import { ComponentBackgroundVariants } from 'src/helpers/Component/Component';
import { useOnlineScheduling } from 'lib/forms/OnlineScheduling/OnlineSchedulingContext';

export type FormWithContentBlockProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.Forms.FormWithContentBlock.FormWithContentBlock;

export type FormWidth = 'one-third' | 'half';
export type FormAlignments = 'left' | 'right';

const FormWithContentBlock = (props: FormWithContentBlockProps) => {
  const { rendering, fields } = props;
  const [inOSModal, setInOSModal] = useState(false);
  const onlineSchedulingContext = useOnlineScheduling();

  const isInOSModal = () => {
    try {
      return onlineSchedulingContext?.isInline ? true : window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  useEffect(() => {
    setInOSModal(isInOSModal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineSchedulingContext?.isInline]);

  if (!props?.fields) {
    return <></>;
  }

  const isContentBlockPresent = rendering?.placeholders?.contentBlock?.length > 0;
  const containerAlignment = getEnum<FormWidth>(fields?.containerAlignment);
  const formAlignment = getEnum<FormAlignments>(fields?.formAlignment);
  const setBackgroundColor =
    getEnum<ComponentBackgroundVariants>(fields?.backgroundColor) ?? 'white';

  return (
    <Component
      variant="full"
      gap="gap-x-0"
      padding="px-0"
      backgroundVariant={
        isContentBlockPresent && containerAlignment === 'half' && setBackgroundColor === 'gray'
          ? 'gray'
          : !isContentBlockPresent && setBackgroundColor === 'gray'
          ? 'gray'
          : ''
      }
      dataComponent="forms/formwithcontentblock"
      {...props}
    >
      {isContentBlockPresent && (
        <div
          className={classNames(
            inOSModal ? 'col-span-12 flex ml:grid' : 'col-span-12 flex py-ml ml:grid ml:!py-m',
            containerAlignment === 'one-third' && '-!mx-l md:grid-cols-12 md:gap-s lg:mx-auto',
            formAlignment === 'right' && 'flex-col-reverse max-md:flex-col',
            formAlignment === 'left' && 'flex-col',
            containerAlignment === 'half' &&
              'gap-s px-m md:max-w-screen-lg ml:grid-cols-12 ml:max-lg:px-m lg:mx-auto'
          )}
        >
          {/* Left section */}
          <div
            className={classNames(
              inOSModal ? 'top-0 left-0 col-span-12 h-full ml:absolute ' : 'col-span-12',
              containerAlignment === 'one-third' &&
                formAlignment === 'right' &&
                '!py-xxs ml:col-span-4 ml:!py-0 ml:px-l',
              containerAlignment === 'one-third' &&
                formAlignment === 'left' &&
                '!px-m !py-ml ml:col-span-6 ml:!col-start-2 ml:!px-0',
              containerAlignment === 'one-third' &&
                formAlignment === 'right' &&
                setBackgroundColor === 'gray' &&
                'bg-light-gray',
              containerAlignment === 'half' && '-mt-s pb-s ml:mt-0 ml:pb-0',
              containerAlignment === 'half' &&
                formAlignment === 'right' &&
                'ml:col-span-6 ml:border-r ml:border-solid ml:border-gray',
              containerAlignment === 'half' &&
                formAlignment === 'left' &&
                'border-b border-solid border-gray ml:col-span-5 ml:border-none'
            )}
          >
            {/* If formAlignment === 'left' => render form on left side, contentBlock - on right side */}
            <Placeholder
              name={formAlignment === 'left' ? 'form' : 'contentBlock'}
              stepperBackground={containerAlignment === 'half' && setBackgroundColor}
              rendering={rendering}
            />
          </div>
          {/* buffer */}
          <div className="px-m ml:col-span-1"></div>

          {/* Right section */}
          <div
            className={classNames(
              'col-span-12',
              containerAlignment === 'one-third' &&
                formAlignment === 'right' &&
                '!px-m py-xxs ml:col-span-6 ml:!col-start-6 ml:py-m ml:!px-0',
              containerAlignment === 'one-third' &&
                formAlignment === 'left' &&
                'ml:col-span-4 ml:!col-start-9 ml:px-l',
              containerAlignment === 'one-third' &&
                formAlignment === 'left' &&
                setBackgroundColor === 'gray' &&
                'bg-light-gray',
              containerAlignment === 'half' &&
                formAlignment === 'left' &&
                '-mx-m -mt-s md:col-span-6 ml:-ml-s ml:border-l ml:border-solid ml:border-gray ml:pt-0',
              containerAlignment === 'half' &&
                formAlignment === 'right' &&
                'mx-m border-b border-solid border-gray py-s md:col-span-5 md:col-start-8 ml:mx-0 ml:border-none ml:pt-0'
            )}
          >
            {/* If formAlignment === 'right' => render form on right side, contentBlock - on left side */}
            <Placeholder
              name={formAlignment === 'right' ? 'form' : 'contentBlock'}
              rendering={rendering}
              stepperBackground={containerAlignment === 'half' && setBackgroundColor}
            />
          </div>
        </div>
      )}
      {/* If isContentBlockPresent is not present, then render form only */}
      {!isContentBlockPresent && (
        <div
          className={classNames(
            'col-span-12 grid px-m !py-m ml:mx-auto ml:max-w-screen-md ml:!px-l'
          )}
        >
          <Placeholder
            name={containerAlignment ? 'form' : 'contentBlock'}
            rendering={rendering}
            stepperBackground={setBackgroundColor}
          />
        </div>
      )}
    </Component>
  );
};

export default withDatasourceCheck()<FormWithContentBlockProps>(FormWithContentBlock);
