import { ThemeFile } from 'lib/context/ThemeContext';

export const ReviewProductImageTheme: ThemeFile = {
  aw: {
    classes: {},
  },
  rba: {
    classes: {
      productImage: 'ml-auto mr-auto max-w-fit',
    },
  },
};
