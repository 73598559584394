import React from 'react';
import { SliderWrapper } from 'src/helpers/SliderWrapper';
import { useTheme } from 'lib/context/ThemeContext';
import { CarouselDynamicReviewsTheme } from './CarouselDynamicReviews.theme';
import { ComponentBackgroundVariants } from 'src/helpers/Component/Component';
import { StarRating } from 'src/helpers/StarRating';
import { ReviewDetails } from 'lib/reviews/types';

interface RenderReviewSliderProps {
  slidesData: ReviewDetails[] | null;
  sliderSettings: { [key: string]: unknown };
  style: ComponentBackgroundVariants;
}

const RenderReviewSlider: React.FC<RenderReviewSliderProps> = ({
  slidesData,
  sliderSettings,
  style,
}) => {
  const { themeData } = useTheme(CarouselDynamicReviewsTheme(style));

  if (!Array.isArray(slidesData)) {
    console.error('slidesData is not an array: ', slidesData);
    return <></>;
  }

  return (
    <SliderWrapper sliderSettings={sliderSettings}>
      {slidesData?.map((reviewItem, index) => {
        return (
          <div key={index} className={themeData.classes.contentClasses.sectionDivWrapper}>
            <div className={themeData.classes.contentClasses.copyContainerClass}>
              <div className={themeData.classes.contentClasses.ratingsIconsList}>
                <StarRating iconSize="xl" reviewStars={reviewItem?.review?.reviewRating} />
              </div>
              {reviewItem?.review?.reviewText ? (
                <div className={themeData.classes.contentClasses.body}>
                  {reviewItem.review.reviewText}
                </div>
              ) : (
                <></>
              )}
              <div className={themeData.classes.contentClasses.additionalInfo}>
                {reviewItem?.customerName ? (
                  <div className={themeData.classes.contentClasses.reviewerName}>
                    {reviewItem.customerName}
                  </div>
                ) : (
                  <></>
                )}
                {reviewItem?.city || reviewItem?.state ? (
                  <div className={themeData.classes.contentClasses.reviewerDetails}>
                    {reviewItem.city}, {reviewItem.state}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </SliderWrapper>
  );
};

export default RenderReviewSlider;
