/* eslint-disable prettier/prettier */
import { fetchInternalAPIWithQueryString } from 'lib/utils/api-request-utils';
import { Reviews, Summary } from '../types/reputation-reviews';

export async function getReputationReviews(locationId:string): Promise<Reviews[]> {
  const url = `${process.env.EW_ENTERPRISE_WEB_APIv2_URL}/api/rba/affiliate/get-reputation-reviews?locationID=${locationId}`;
  const response = await fetchInternalAPIWithQueryString(url);
  const data= await response.json();

  const mappedReviews = Array.isArray(data.reviews)
      ? data.reviews.map((review: any) => ({
        reviewer: { name: review.reviewer?.name || 'Unknown Author' },
        rating: review.rating || 0,
        comment: review.comment || '',
      }))
    : [];

  return mappedReviews;
}

export async function getReputationSummary(locationId: string): Promise<Summary> {
  const url = `${process.env.EW_ENTERPRISE_WEB_APIv2_URL}/api/rba/affiliate/get-reputation-summary?locationIDs=${locationId}`;
  const response = await fetchInternalAPIWithQueryString(url);
  const data= await response.json();

  const reviewSummary: Summary = {
    summary:{
      overall:{
        count: data?.summary?.overall?.count || 0,
        rating: data?.summary?.overall?.rating || 0,
      }
    }
  }

   return reviewSummary;
}
