// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
import {
  LinkField,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import { getEnum, getHeadingLevel } from 'lib/utils';
import { HeadlineWithNavigationTheme } from './HeadlineWithNavigation.theme';
import { IconTypes } from 'src/helpers/SvgIcon/SvgIcon';
import SvgIcon from 'src/helpers/SvgIcon/SvgIcon';
import ImagePrimary from 'src/helpers/Media/ImagePrimary';
import LinkWrapper from 'src/helpers/LinkWrapper/LinkWrapper';
import { Subheadline } from 'src/helpers/Subheadline';
import Headline from 'src/helpers/Headline/Headline';
import { useCurrentScreenType } from 'lib/utils/get-screen-type';
export type HeadlineWithNavigationLinkProps =
  Feature.EnterpriseWeb.Enterprise.Components.General.HeadlineWithNavigation.HeadlineWithNavigationLink;

export type HeadlineWithNavigationProps =
  Feature.EnterpriseWeb.Enterprise.Components.General.HeadlineWithNavigation.HeadlineWithNavigation & {
    fields: {
      children: [HeadlineWithNavigationLinkProps];
    };
  };

export type ComponentSpacing = '8' | '4';

const HeadlineWithNavigation = (props: HeadlineWithNavigationProps) => {
  const spacing = getEnum<ComponentSpacing>(props.fields?.componentSpacing) || 'standard';
  const spacingValue = spacing === 'standard' ? '8' : '4';
  const context = useSitecoreContext();
  const breadcrumb = (context && context.sitecoreContext?.breadcrumb) || [];
  const { screenType } = useCurrentScreenType();
  const { themeData } = useTheme(HeadlineWithNavigationTheme(spacingValue));
  const noOfTabs = props.fields?.children?.length;
  const headlineCheck = props.fields?.subheadlineText.value;
  return (
    <div
      data-component="general/HeadlineWithNavigation"
      className={breadcrumb?.length === 1 ? 'relative pt-[55px] ml:pt-0' : 'relative'}
    >
      {props?.fields?.headlineText?.value && (
        <div className={themeData.classes.headlineContainer}>
          <div className="col-span-12">
            <Headline
              useTag={getHeadingLevel('h1', props.fields?.headlineLevel)}
              classes={themeData.classes.headline}
              {...props}
            />
          </div>
        </div>
      )}
      <div className={themeData.classes.heroContainer}>
        <ImagePrimary
          imageLayout="fill"
          ratio="hero"
          additionalDesktopClasses="h-[280px] ml:h-[422px]"
          additionalMobileClasses="h-[280px]"
          priority
          {...props}
        ></ImagePrimary>
        <div className={themeData.classes.linkContainer}>
          <Subheadline classes={themeData.classes.subheadlineStyle} {...props} />
          {props.fields?.children?.map((_item: HeadlineWithNavigationLinkProps, i: number) => {
            const _linkField = _item.fields?.navigationLink as LinkField;
            const _icon = getEnum<IconTypes>(_item.fields?.navigationIcon);

            return (
              <div key={i} className={themeData.classes.linkStyle}>
                <LinkWrapper
                  className={`${themeData.classes.linkWrapperStyle} ${
                    (noOfTabs > 3 && headlineCheck != '' && screenType === 'mml') ||
                    screenType === 'ml'
                      ? 'text-xs'
                      : 'text-s'
                  }`}
                  field={_linkField}
                  ariaLabel={{
                    value: (_item.fields?.navigationIcon?.name as string) || 'Navigation Icon',
                  }}
                >
                  {_item.fields?.navigationIcon && (
                    <div className={themeData.classes.svgWrapper}>
                      <SvgIcon icon={_icon} className={themeData.classes.svgIconStyle} />
                    </div>
                  )}
                </LinkWrapper>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withDatasourceCheck()<HeadlineWithNavigationProps>(HeadlineWithNavigation);
