import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import SvgIcon from 'src/helpers/SvgIcon/SvgIcon';
import { useFlyoutStore } from 'src/helpers/Flyout/flyout.store';
import { Affiliate } from 'lib/context/AffiliateContext';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { Spinner } from 'src/helpers/Spinner';
import TagManager from 'react-gtm-module';
import { GetDomainURL, CANADIAN_ZIP_REGEX, SiteType, US_ZIP_REGEX } from '../Domain/DomainUtils';
import { getCookie } from 'lib/utils/client-storage-utils';
export const ChangeLocation = () => {
  const [zipInputValue, setZipInputValue] = useState('');
  const [isValidZip, setIsValidZip] = useState(false);
  const [zipType, setZipType] = useState<SiteType>('US');
  const [isDataBeingFetched, setIsDataBeingFetched] = useState(false);
  const currentSiteRef = useRef({ isUSSite: true });
  const zipInputRef = useRef<HTMLInputElement>(null);
  const sitecoreContext = useSitecoreContext();
  const { currentZip, setCurrentZip } = useFlyoutStore();
  const USPostRegex = new RegExp(US_ZIP_REGEX);
  const CanadianPostRegex = new RegExp(CANADIAN_ZIP_REGEX);
  const [isLocationVisible, setLocationVisibility] = useState(false);

  useEffect(() => {
    currentSiteRef.current.isUSSite = window.location.hostname.endsWith('.com');
    const zip = (getCookie('currentZip') as string)?.replaceAll('%20', ' ');
    setZipInputValue('');
    setCurrentZip(zip);
    setZipInputValue(zip);
    setIsValidZip(true);
  }, [setCurrentZip]);

  const setZipCode = (value: string) => {
    setZipInputValue(value);
    setIsValidZip(CanadianPostRegex.test(value) || USPostRegex.test(value));
    setZipType(CanadianPostRegex.test(value) ? 'Canada' : 'US');
  };

  const changeZipCode = async () => {
    if (zipInputValue === currentZip || !isValidZip) {
      setZipInputValue('');
      zipInputRef.current?.focus();
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        zipcode: zipType === 'Canada' ? zipInputValue.substring(0, 3) : zipInputValue,
        language: sitecoreContext.sitecoreContext.language ?? 'en',
      }),
    };

    setIsDataBeingFetched(true);

    const response = await fetch(
      '/api/rba/affiliate/get-affiliate-data-by-zipcode/',
      requestOptions
    );
    const data: { affiliateData: Affiliate } = await response.json();

    if (typeof window !== 'undefined') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'change_location',
          zip_code: zipInputValue,
        },
      });
      window.location.href = GetDomainURL(
        data?.affiliateData,
        zipInputValue,
        isCrossDomainZipType()
      );
    }
  };

  const isCrossDomainZipType = () => {
    return (
      (currentSiteRef.current.isUSSite && zipType === 'Canada') ||
      (!currentSiteRef.current.isUSSite && zipType === 'US')
    );
  };

  return (
    <>
      <div className="relative w-[285px]">
        {!isLocationVisible && (
          <div className="flex items-end space-x-2">
            {currentZip && (
              <div className="flex items-center text-sm  font-bold">
                <SvgIcon className="mr-xxxs" icon="location-pin" size="md" />
                <Text
                  tag="span"
                  field={{
                    value: currentZip,
                  }}
                />
              </div>
            )}

            <span
              className="flex cursor-pointer items-center text-sm font-bold text-darkprimary"
              onClick={() => {
                setLocationVisibility(true);
                setZipInputValue('');
              }}
            >
              Change location
            </span>
          </div>
        )}
        {isLocationVisible && (
          <div className="flex items-center space-x-2">
            <div className="relative">
              <input
                ref={zipInputRef}
                disabled={isDataBeingFetched}
                onChange={(e) => setZipCode(e.currentTarget.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    changeZipCode();
                  }
                }}
                value={zipInputValue}
                type="text"
                name="change-zip-textbox"
                id="changeZipTextbox"
                className={classNames(
                  zipInputValue && !isValidZip
                    ? 'ring-2 ring-[#F14343] focus:ring-[#F14343]'
                    : 'ring-1 focus:ring-black',
                  'w-[100px] rounded-full border-none text-left text-xxs ring-gray focus:ring-2'
                )}
                placeholder="Zip Code"
              />
              {zipInputValue && !isDataBeingFetched && (
                <div
                  className="absolute right-4 top-1/2 -translate-y-1/2 transform cursor-pointer"
                  onClick={() => setZipInputValue('')}
                >
                  <SvgIcon icon="close" />
                </div>
              )}
              {isDataBeingFetched && (
                <div className="absolute right-[10px] top-1/2 -translate-y-1/2 pt-xxxs">
                  <Spinner size={16} />
                </div>
              )}
            </div>
            {zipInputValue && isValidZip && (
              <button className="text-xxs font-bold text-darkprimary" onClick={changeZipCode}>
                Update
              </button>
            )}

            <button
              className="text-xxs font-bold text-darkprimary"
              onClick={() => setLocationVisibility(false)}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      {zipInputValue && !isValidZip && (
        <span className="text-left text-xxs leading-[22px] text-[#F14343]">
          Enter a valid zip code
        </span>
      )}
    </>
  );
};
