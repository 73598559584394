/* eslint-disable react-hooks/rules-of-hooks */
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import React, { useRef } from 'react';
import * as yup from 'yup';
import { FormFieldProps } from 'lib/forms/FormFieldProps';
import { Field, FormikErrors, FormikTouched, FormikValues, useFormikContext } from 'formik';
import { useTheme } from 'lib/context/ThemeContext';
import { FormFieldsTheme } from './FormFields.Theme';
import classNames from 'classnames';
import FieldWrapper from '../Structure/FieldWrapper/FieldWrapper';

import {
  getKeyValuePairList,
  getValidatonSchema,
  getValueProviderValue,
} from 'lib/forms/FormFieldUtils';
import { DropdownProps } from './Dropdown';

export const getInitialValue = (
  props: TradeProTypeProps,
  additionalDetails?: Record<string, unknown> | undefined
) => {
  const valueProviderVal = getValueProviderValue(props, additionalDetails);
  if (
    valueProviderVal &&
    getKeyValuePairList(props).some(
      (option: { value: string }) => option.value === valueProviderVal
    )
  ) {
    return valueProviderVal;
  }

  let defaultSelectedValue;
  if (props.fields?.defaultValue) {
    defaultSelectedValue =
      props.fields?.defaultValue?.fields[props.fields?.valueFieldName?.value]?.value;
  } else if (!props.fields?.showDefaultDisplay?.value) {
    defaultSelectedValue = getKeyValuePairList(props)?.[0].value;
  } else {
    defaultSelectedValue = '';
  }

  return defaultSelectedValue;
};

export const getDropdownValidationSchema = (props: TradeProTypeProps, schema: yup.AnyObject) => {
  const { validations } = props?.fields;
  if (validations) {
    const validator = getValidatonSchema('string', props);
    if (validator) {
      schema[props?.fields?.fieldName?.value] = validator;
    }
  }
  return schema;
};

export type TradeProTypeProps = Feature.EnterpriseWeb.Enterprise.Forms.Fields.Dropdown &
  FormFieldProps;

const TradeTypeDropDown = (props: DropdownProps): JSX.Element => {
  let formikContext;
  try {
    formikContext = useFormikContext<FormikValues>();
  } catch (error) {
    console.log('Error accessing Formik context:', error);
    return <React.Fragment />; // Avoid rendering if formik context is unavailable
  }

  const userType = formikContext.values['user-type'];

  const { themeName, themeData } = useTheme(FormFieldsTheme);

  const { errors, touched, values } = formikContext;
  const dropdownContainerRef = useRef<HTMLDivElement>(null);

  const isInvalid =
    (touched as FormikTouched<FormikValues>)[props?.fields?.fieldName?.value] &&
    (errors as FormikErrors<FormikValues>)[props?.fields?.fieldName?.value];

  const dropdownOptions = getKeyValuePairList(props);

  if (props.fields?.showDefaultDisplay?.value) {
    dropdownOptions?.unshift({ label: props.fields?.defaultDisplay?.value || '', value: '' });
  }

  if (userType === 'Homeowner' || userType === '') {
    return <React.Fragment />; // Hide component if user-type is not "Trade Professional"
  }

  return (
    <FieldWrapper {...props}>
      <div className="relative" ref={dropdownContainerRef}>
        <Field
          id={props.id}
          name={props?.fields?.fieldName?.value}
          as="select"
          value={values[props?.fields?.fieldName?.value]}
          className={classNames(
            'relative pr-ml',
            themeData.classes.input,
            isInvalid ? themeData.classes.errorOutline : '',
            values[props?.fields?.fieldName?.value] ? 'border-black' : ''
          )}
        >
          {dropdownOptions?.map((option: Record<string, string>) => (
            <option
              key={option.value}
              value={option.value}
              disabled={themeName === 'aw' && option.value === ''}
            >
              {option.label}
            </option>
          ))}
        </Field>
      </div>
    </FieldWrapper>
  );
};

export default TradeTypeDropDown;
