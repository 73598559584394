import { create } from 'zustand';

type Store = {
  exactRating: number;
  setExactRating: (rating: number) => void;
};

export const useReviewSummaryStore = create<Store>()((set) => ({
  exactRating: 0,
  setExactRating: (rating: number) => set(() => ({ exactRating: rating })),
}));
