// Global
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs/types/sharedTypes/component-props';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import Component from 'src/helpers/Component/Component';
import React, { useRef, useState, useEffect } from 'react';
import Head from 'next/head';

interface ReputationReviewsWidgetStaticProps {
  htmlData?: string;
}
export type ReputationReviewsWidgetProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.Reviews.ReputationReviewsWidget &
    ReputationReviewsWidgetStaticProps;
const ReputationReviewsWidget = (props: ReputationReviewsWidgetProps): JSX.Element => {
  const [htmlData, setHtmlData] = useState<string>(props?.htmlData ?? null);
  const [widgetUrl, setWidgetUrl] = useState<string>(props?.fields?.widgetUrl?.value);
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!widgetUrl) {
      return;
    }

    const select = widgetRef.current?.querySelector('#sort') as HTMLSelectElement;
    const nextPage = widgetRef.current?.querySelector('.next-page') as HTMLAnchorElement;
    const previousPage = widgetRef.current?.querySelector('.previous-page') as HTMLAnchorElement;

    const newUrl = new URL(widgetUrl);
    const searchParams = new URLSearchParams(newUrl?.search);
    const sortParam = searchParams.get('sort');
    if (!!sortParam && !!select) {
      select.value = sortParam;
    }

    nextPage?.addEventListener('click', handleNextPageClickEvent);
    previousPage?.addEventListener('click', handleNextPageClickEvent);
    select?.addEventListener('change', handleSelectEvent);

    return () => {
      nextPage?.removeEventListener('click', handleNextPageClickEvent);
      previousPage?.removeEventListener('click', handleNextPageClickEvent);
      select?.removeEventListener('change', handleSelectEvent);
    };
  }, [htmlData]);

  function handleSelectEvent(event: any) {
    updateWidgetData('sort', (event?.target as HTMLSelectElement)?.value);
  }

  function handleNextPageClickEvent(event: any) {
    event?.preventDefault();
    const anchor = event?.target as HTMLAnchorElement;
    const startValue = new URLSearchParams(new URL(anchor.href).search)?.get('start');
    if (!startValue) {
      return;
    }
    updateWidgetData('start', startValue);
  }

  const updateWidgetData = async (queryParameter: string, value: string) => {
    const newUrl = new URL(widgetUrl);
    const searchParams = new URLSearchParams(newUrl?.search);
    searchParams.set(queryParameter, value);
    newUrl.search = searchParams.toString();
    setWidgetUrl(newUrl.toString());
    const data = await fetchWidgetData(newUrl.toString());
    setHtmlData(data?.htmlData);
  };

  return (
    <Component variant="lg" dataComponent="reviews/reputationreviewswidget" {...props}>
      <Head>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://widgets.reputation.com/lib/widgets.css"
        />
      </Head>
      <div className="col-span-12" dangerouslySetInnerHTML={{ __html: htmlData }} ref={widgetRef} />
    </Component>
  );
};

export const getStaticProps: GetStaticComponentProps = async (rendering) => {
  const datasource = rendering as ReputationReviewsWidgetProps;
  return await fetchWidgetData(datasource?.fields?.widgetUrl?.value);
};

const fetchWidgetData = async (url: string) => {
  if (!url) {
    return {
      htmlData: '',
    };
  }
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Reputation.com response status: ${response.status}`);
    }

    const html = await response.text();
    return {
      htmlData: html,
    };
  } catch (error) {
    console.error(error);
    return {
      htmlData: '',
    };
  }
};

export default withDatasourceCheck()<ReputationReviewsWidgetProps>(ReputationReviewsWidget);
